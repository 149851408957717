import { GridSelectionModel } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import {
  createFullPath,
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  InventorProperties,
  toDraftTemplateInputParameter,
  toDraftTemplateIProperty,
} from 'mid-addin-lib';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';
import { useAsyncFetchDataWithArgs, useLogAndShowNotification } from 'mid-react-common';
import { TemplateInputType } from 'mid-types';
import { isDraftTemplateIProperty, isNotDraftTemplateIProperty } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { fetchAllInventorData } from './utils';

export type HandleSelectedInputUpdateType = (input: DraftTemplateInput, property: { [key: string]: any }) => void;

type UserInputsSelectionProps = {
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface UseInputsSelectionState {
  inventorData: InventorProperties | null;
  selectedParameters: DraftTemplateInputParameter[];
  selectedIProperties: DraftTemplateIProperty[];
  loading: boolean;
  error: Error | null;
  setSelectedParameters: (names: GridSelectionModel) => void;
  setSelectedIProperties: (ids: GridSelectionModel) => void;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
  handleAddInputsButtonClick: () => void;
}

const useInputsSelection = ({ setIsEditStep }: UserInputsSelectionProps): UseInputsSelectionState => {
  const { currentDraft, addCurrentDraftInputs, updateCurrentDraftParameter, updateCurrentDraftIProperty } =
    useContext(DataContext);

  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();
  const [convertedInputs, setConvertedInputs] = useState<{
    parameters: DraftTemplateInputParameter[];
    iProperties: DraftTemplateIProperty[];
  }>({ parameters: [], iProperties: [] });

  const [localSelectedParameters, setLocalSelectedParameters] = useState<DraftTemplateInputParameter[]>(
    currentDraft.inputs.filter(isNotDraftTemplateIProperty),
  );
  const [localSelectedIProperties, setLocalSelectedIProperties] = useState<DraftTemplateIProperty[]>(
    currentDraft.inputs.filter(isDraftTemplateIProperty),
  );

  // Initial Setup, getting assembly from currentDraft, which is needed to fetch Inventor Data
  useEffect(() => {
    const topLevelFolder = currentDraft.topLevelFolder;
    const assembly = currentDraft.assembly;
    if (topLevelFolder && assembly) {
      setFetchDependencyList([createFullPath(topLevelFolder, assembly)]);
    }
  }, [currentDraft.topLevelFolder, currentDraft.assembly]);

  const {
    data: inventorData,
    loading,
    error,
  } = useAsyncFetchDataWithArgs<InventorProperties>(fetchAllInventorData, fetchDependencyList);

  useLogAndShowNotification(error, text.notificationGetPartOrAssemblyPropertiesFailed);

  // Convert Inventor data to draft interface
  useEffect(() => {
    if (inventorData) {
      const convertedParameters = inventorData.parameters.map(toDraftTemplateInputParameter);
      const convertedIProperties = inventorData.iProperties.map(toDraftTemplateIProperty);
      setConvertedInputs({ parameters: convertedParameters, iProperties: convertedIProperties });
    }
  }, [inventorData]);

  const setSelectedParameters = (names: GridSelectionModel) => {
    const selectedParameters = convertedInputs.parameters.filter((input) => names.includes(input.name));
    setLocalSelectedParameters(selectedParameters);
  };

  const setSelectedIProperties = (ids: GridSelectionModel) => {
    const selectedIProperties = convertedInputs.iProperties.filter((input) => ids.includes(input.id));
    setLocalSelectedIProperties(selectedIProperties);
  };

  const handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType = (input, property) => {
    if (input.type === TemplateInputType.IProperty) {
      updateCurrentDraftIProperty(input, property);
    } else {
      updateCurrentDraftParameter(input, property);
    }
  };

  const handleAddInputsButtonClick = () => {
    addCurrentDraftInputs([...localSelectedParameters, ...localSelectedIProperties]);
    setIsEditStep(true);
  };

  return {
    inventorData,
    selectedParameters: localSelectedParameters,
    selectedIProperties: localSelectedIProperties,
    loading,
    error,
    setSelectedParameters,
    setSelectedIProperties,
    handleSelectedInputDataStoreUpdate,
    handleAddInputsButtonClick,
  };
};

export default useInputsSelection;
