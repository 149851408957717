import Button from '@adsk/alloy-react-button';
import text from 'inventor.text.json';
import { TabContainer } from 'mid-react-common';
import React from 'react';
import AssemblySection from './FormStep/AssemblySection';
import IPJSection from './FormStep/IPJSection';
import TopFolderSection from './FormStep/TopFolderSection';
import Preview from './PreviewStep/Preview';
import {
  SourceContentForm,
  SourceContentFormWrapper,
  SourceContentHeader,
  SourceContentRightButton,
  SourceContentTitle,
} from './SourceContentTab.styles';
import useSourceContent from './useSourceContent';

const SourceContentTab: React.FC = (): JSX.Element => {
  const {
    localTopLevelFolder,
    handleSelectTopLevelFolderClick,
    localRelativeInventorProjectPath,
    handleSelectInventorProjectClick,
    localRelativeAssemblyPath,
    handleSelectTopLevelAssemblyClick,
    localFullAssemblyPath,
    isPreviewStep,
    isPreviewLoading,
    thumbnailInBase64,
    handleNextButtonClick,
  } = useSourceContent();

  return (
    <TabContainer>
      {isPreviewStep ? (
        localTopLevelFolder &&
        localRelativeAssemblyPath && (
          <Preview
            topLevelFolder={localTopLevelFolder}
            iPJ={localRelativeInventorProjectPath}
            topLevelAssembly={localRelativeAssemblyPath}
            path={localFullAssemblyPath}
            thumbnailInBase64={thumbnailInBase64?.base64}
          />
        )
      ) : (
        <>
          <SourceContentHeader>
            <SourceContentTitle>{text.sourceContentTitle}</SourceContentTitle>
            <SourceContentRightButton
              data-testid="source-content-button-next"
              onClick={handleNextButtonClick}
              disabled={!localTopLevelFolder || !localRelativeAssemblyPath}
              size={Button.SIZES.SMALL}
              variant={Button.VARIANTS.PRIMARY}
              loading={isPreviewLoading}
            >
              {text.buttonNext}
            </SourceContentRightButton>
          </SourceContentHeader>
          <SourceContentFormWrapper>
            <SourceContentForm>
              <TopFolderSection
                topLevelFolder={localTopLevelFolder}
                handleSelectTopLevelFolderClick={handleSelectTopLevelFolderClick}
              />
              {localTopLevelFolder && (
                <>
                  <IPJSection
                    iPJ={localRelativeInventorProjectPath}
                    handleSelectInventorProjectClick={handleSelectInventorProjectClick}
                  />
                  <AssemblySection
                    topLevelAssembly={localRelativeAssemblyPath}
                    handleSelectTopLevelAssemblyClick={handleSelectTopLevelAssemblyClick}
                    iPJ={localRelativeInventorProjectPath}
                  />
                </>
              )}
            </SourceContentForm>
          </SourceContentFormWrapper>
        </>
      )}
    </TabContainer>
  );
};

export default SourceContentTab;
