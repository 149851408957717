import Button from '@adsk/alloy-react-button';
import DocumentTwoIcon from '@adsk/alloy-react-icon/es/lib/DocumentTwoIcon';
import PlusCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/PlusCircleFilledIcon';
import text from 'inventor.text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LeftSection, RightButton, RightSection, Subtitle } from 'mid-react-common';
import React from 'react';

const Initial: React.FC<{
  handleNewTemplateClick: () => void;
  handleManageTemplatesClick: () => void;
  handleMidWebPortalClick: () => void;
}> = ({ handleNewTemplateClick, handleManageTemplatesClick, handleMidWebPortalClick }): JSX.Element => {
  const { enableTemplateManagement } = useFlags();

  return (
    <>
      <LeftSection>
        <Subtitle>{text.subHeaderTitleInitial}</Subtitle>
      </LeftSection>
      <RightSection>
        <RightButton onClick={handleMidWebPortalClick} size={Button.SIZES.SMALL} variant={Button.VARIANTS.PRIMARY}>
          {text.buttonGoToMidWebPortal}
        </RightButton>
        {enableTemplateManagement && (
          <RightButton
            renderIcon={(p) => <DocumentTwoIcon {...p} />}
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.PRIMARY}
            onClick={handleManageTemplatesClick}
          >
            {text.manageTemplates}
          </RightButton>
        )}
        <RightButton
          renderIcon={(p) => <PlusCircleFilledIcon {...p} />}
          size={Button.SIZES.SMALL}
          variant={Button.VARIANTS.PRIMARY}
          onClick={handleNewTemplateClick}
          data-testid="button-new-product-definition"
        >
          {text.buttonNewProductDefinition}
        </RightButton>
      </RightSection>
    </>
  );
};

export default Initial;
