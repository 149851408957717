import { GridRowModel, GridSelectionModel } from '@mui/x-data-grid';
import { format } from 'date-fns';
import text from 'inventor.text.json';
import { DraftTemplate, getFullFolderNamedPath } from 'mid-addin-lib';
import {
  MIDEmptyState,
  ModalContext,
  NOTIFICATION_STATUSES,
  NotificationContext,
  TableEmptyStateWrapper,
  initialModalState,
} from 'mid-react-common';
import { EditDraftError, logError } from 'mid-utils';
import { useContext, useState } from 'react';

interface UseDraftTableState {
  tableData: GridRowModel[];
  selectedDraftIds: GridSelectionModel;
  numberOfDraftsSelected: number;
  handleEditTemplateClickWithContext: () => void;
  handleDeleteTemplateClickWithContext: () => void;
  onDraftSelection: (selectionModel: GridSelectionModel) => void;
  renderEmptyState: () => JSX.Element;
}

interface UseDraftTableProps {
  drafts: DraftTemplate[] | null;
  handleEditTemplateClick: (draftTemplate: DraftTemplate) => void;
  handleDeleteTemplatesClick: (draftIds: string[]) => void;
}

const useDraftTable = ({
  drafts,
  handleEditTemplateClick,
  handleDeleteTemplatesClick,
}: UseDraftTableProps): UseDraftTableState => {
  const [selectedDraftIds, setSelectedDraftIds] = useState<GridSelectionModel>([]);
  const numberOfDraftsSelected = selectedDraftIds.length;
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const tableData: GridRowModel[] = drafts
    ? drafts.map((d) => ({
        templateName: d.name,
        numberOfInputs: d.inputs?.length,
        publishTo:
          d.account.name && d.project.name
            ? `${d.account.name}/${d.project.name}/${getFullFolderNamedPath(d.folder)}`
            : text.draftTableColumnPublishToNotPublished,
        lastUpdated: format(d.lastUpdated, 'MMM d, yyyy h:m a'),
        id: d.id,
      }))
    : [];

  const handleEditTemplateClickWithContext = (): void => {
    try {
      if (numberOfDraftsSelected !== 1) {
        throw new EditDraftError(text.canOnlyEditOneDraft, {
          numberOfDraftsSelected,
        });
      }

      const draftId = selectedDraftIds[0];
      const draft = drafts?.find((draft) => draft.id === draftId);

      if (!draft) {
        throw new EditDraftError(text.cannotFindTheDraft, {
          numberOfDraftsSelected,
          draftId,
        });
      }

      handleEditTemplateClick(draft);
    } catch (err: unknown) {
      logError(err);
      showNotification({
        message: text.notificationEditDraftFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const handleDeleteTemplateClickWithContext = (): void => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      title: text.confirmationTitle,
      message: text.productDefinitionDeleteConfirmation,
      onConfirmCallback: () => {
        try {
          handleDeleteTemplatesClick(selectedDraftIds as string[]);
          setSelectedDraftIds([]);
        } catch (err) {
          logError(err);
          throw err;
        }
      },
    });
  };

  const onDraftSelection = (selectedDrafts: GridSelectionModel) => {
    setSelectedDraftIds(selectedDrafts);
  };

  const renderEmptyState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState title={text.EmptyStateNoDraftTemplates} description={text.EmptyStatePleaseCreateDraftTemplates} />
    </TableEmptyStateWrapper>
  );

  return {
    tableData,
    selectedDraftIds,
    numberOfDraftsSelected,
    handleEditTemplateClickWithContext,
    handleDeleteTemplateClickWithContext,
    onDraftSelection,
    renderEmptyState,
  };
};

export default useDraftTable;
