import React from 'react';
import {
  ConfirmationModal,
  EnvironmentInfo,
  Main,
  midTheme,
  ModalContext,
  useModalStore,
  UnderMaintenance,
  ErrorFallback,
  createBugsnagErrorBoundaryComponent,
  ErrorBoundary,
  InventorWebComponentMoniker,
  NotificationsProvider,
  useNotificationStore,
  UserAnalyticsProvider,
} from 'mid-react-common';

import { ThemeProvider } from '@mui/material/styles';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import DataContext from './context/DataStore/Data.context';
import { useStore as useDataStore } from './context/DataStore/dataStore';
import NavigationContext from './context/NavigationStore/Navigation.context';
import { useNavigationStore } from './context/NavigationStore/navigationStore';
import TabProgressContext from './context/TabProgressStore/TabProgress.context';
import { useTabProgressStore } from './context/TabProgressStore/tabProgressStore';
import { useFlags } from 'launchdarkly-react-client-sdk';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY_ADDINS || '',
  moniker: InventorWebComponentMoniker,
  appVersion: process.env.REACT_APP_IVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode } = useFlags();
  const dataStore = useDataStore();
  const navigationStore = useNavigationStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();
  const { topLevelFolder, inventorProject, assembly, inputs, outputs } = dataStore.currentDraft;
  const tabProgressStore = useTabProgressStore({
    topLevelFolder,
    inventorProject,
    assembly,
    inputs,
    outputs,
  });

  const handleResetAppState = (): void => window.location.reload();

  if (enableMaintenanceMode) {
    return (
      <ThemeProvider theme={midTheme}>
        <UnderMaintenance />
      </ThemeProvider>
    );
  }

  const appTree = (
    <ThemeProvider theme={midTheme}>
      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal />
        <NotificationsProvider store={notificationStore}>
          <UserAnalyticsProvider>
            <NavigationContext.Provider value={navigationStore}>
              <DataContext.Provider value={dataStore}>
                <TabProgressContext.Provider value={tabProgressStore}>
                  <Header />
                  <Main>
                    <EnvironmentInfo hostname={window.location.host} />
                    <ScreenManager />
                  </Main>
                </TabProgressContext.Provider>
              </DataContext.Provider>
            </NavigationContext.Provider>
          </UserAnalyticsProvider>
        </NotificationsProvider>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
