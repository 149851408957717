import GlobalStyles from '@mui/material/GlobalStyles';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { mockHostApi } from 'mid-addin-lib';
import { AddInWindow, GlobalStyle, LDProviderConfigWithAnonymousAnalytics, reportWebVitals } from 'mid-react-common';
import { handlers } from 'mid-utils';
import { setupWorker, rest } from 'msw';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (
  process.env.REACT_APP_CYPRESS_BUILD ||
  (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_DEVELOPMENT === 'browser')
) {
  const mockWorker = setupWorker(...handlers);

  // Make the `worker` and `rest` references available globally for cypress testing
  window.msw = { mockWorker, rest };

  // Starting msw mock worker to intercept FORGE API calls
  mockWorker.start();

  // Mocking HostApi library
  (window as AddInWindow).hostApi = mockHostApi;
}

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. This will ensure that the
// <style> tag generated would not recalculate on each render.
const globalStyles = <GlobalStyles styles={GlobalStyle} />;

(async () => {
  const LDProvider = await asyncWithLDProvider(LDProviderConfigWithAnonymousAnalytics);

  ReactDOM.render(
    <React.StrictMode>
      {globalStyles}
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
