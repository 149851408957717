import {
  DynamicContentInput,
  DynamicContentProduct,
  PostVariantInput,
  PostVariantOutput,
  PostVariantPayload,
  TemplateInputType,
} from 'mid-types';
import { GenerateOutputError, logError } from 'mid-utils';
import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
  InventorOutputFileInfo,
  InventorOutputType,
  UploadContentResult,
} from '../interfaces/inventorAutomation';
import {
  DraftTemplate,
  DraftTemplateInput,
  DraftTemplateOutput,
  DraftTemplatePublishResult,
  OutputType,
  PublishStatus,
} from '../interfaces/templates';
import { getUUID } from '../services/uuid.service';
import { DataCategory, uploadFile } from './cloudStorage';
import { draftToDCTemplate } from './drafts';
import { compressFolder, deleteFile } from './filesystem';
import { generateOutputs, getEngineVersion, getModelStates } from './inventor';
import { postProduct } from './products';
import { addQuotesToTextParameters, addDoubleQuotes, saveToFile } from './tools';
import { postVariantToAPI } from './variants';

export const draftInputsToInventorInputs = (draftInputs: DraftTemplateInput[]): InventorInput[] =>
  draftInputs.map((draftInput) => {
    switch (draftInput.type) {
      case TemplateInputType.Boolean:
        return {
          name: draftInput.name,
          value: draftInput.value.toString(),
          isProperty: false,
        };

      case TemplateInputType.Text:
        return {
          name: draftInput.name,
          value: addDoubleQuotes(draftInput.value),
          isProperty: false,
        };

      case TemplateInputType.Numeric:
        return {
          name: draftInput.name,
          value: draftInput.value.toPrecision(17),
          isProperty: false,
        };

      case TemplateInputType.MultiValueText:
        return {
          name: draftInput.name,
          value: addDoubleQuotes(draftInput.value ?? ''),
          isProperty: false,
        };

      case TemplateInputType.MultiValueNumeric:
        return {
          name: draftInput.name,
          value: draftInput.value?.toPrecision(17) ?? '',
          isProperty: false,
        };
      case TemplateInputType.IProperty:
        return {
          name: draftInput.name,
          value: draftInput.value,
          isProperty: true,
        };
    }
  });

export const draftOutputsToInventorOutputs = (draftOutputs: DraftTemplateOutput[]): InventorOutput[] =>
  draftOutputs
    .filter((draftOutput) => draftOutput.type === OutputType.RFA)
    .map((draftOutput) => ({
      type: InventorOutputType.RFA,
      modelStates: draftOutput.options?.modelStates,
    }));

export const generateOutputFiles = async (draftTemplate: DraftTemplate): Promise<GenerateOutputsResult> => {
  const inventorInputs = draftInputsToInventorInputs(draftTemplate.inputs);
  const inventorOutputs = draftOutputsToInventorOutputs(draftTemplate.outputs);

  // Request a thumbnail image for the primary model state.
  // This will serve as the product thumbnail and match what is displayed in the UI.

  const topFolderPath = draftTemplate.topLevelFolder;
  const documentFilePath = `${topFolderPath}${draftTemplate.assembly}`;
  const avaialbleModelStates = await getModelStates(documentFilePath);
  const tempModelStates: string[] = [];
  tempModelStates.push(avaialbleModelStates[0]);

  inventorOutputs.push({
    type: InventorOutputType.THUMBNAIL,
    modelStates: tempModelStates,
  });

  const generateOutputsResult = await generateOutputs(topFolderPath, documentFilePath, inventorInputs, inventorOutputs);

  return generateOutputsResult;
};

export const uploadContentAsFile = async (
  projectId: string,
  content: string,
  fileName: string,
  fileExtension: string,
  category: DataCategory,
): Promise<UploadContentResult> => {
  const filePath = await saveToFile(content, fileName, fileExtension);
  const objectKey = await uploadFile(projectId, filePath, category, 'application/json');
  return { filePath, objectKey };
};

export const cleanupFiles = async (tempFiles: string[]): Promise<void> => {
  await Promise.all(
    tempFiles.map(async (filePath) => {
      await deleteFile(filePath);
    }),
  );
};

// TODO: The value of a DynamicContentInput should not be potentially undefined.
// (Looks like this is still also used for draft templates mixing up separate concerns.)
export const dynamicContentInputsToVariantInputs = (dcInputs: DynamicContentInput[]): PostVariantInput[] =>
  dcInputs.map((dcInput) => ({ name: dcInput.name, value: dcInput.value! }));

/**
 * Publishes a draft template as dynamic content product.
 *
 * @param draftTemplate The draft template to be published.
 * @returns An object containing the result of the operation.
 */
export const publishProductFromDraft = async (draftTemplate: DraftTemplate): Promise<DraftTemplatePublishResult> => {
  const updatedDraftTemplate = { ...draftTemplate, inputs: addQuotesToTextParameters(draftTemplate.inputs) };
  let outputFiles: InventorOutputFileInfo[] = [];
  let tempFiles: string[] = [];
  try {
    // generate the output files through local Inventor
    const generateOutputsResult = await generateOutputFiles(updatedDraftTemplate);
    if (!generateOutputsResult.success) {
      // TODO: need to define what to report in case of a failure
      throw new GenerateOutputError(generateOutputsResult.report, {
        report: generateOutputsResult.report,
      });
    }
    outputFiles = generateOutputsResult.outputFiles!;
    tempFiles = tempFiles.concat(outputFiles.map((x) => x.filePath));

    // upload the product thumbnail
    const thumbnailFilePath = outputFiles.find((x) => x.type === InventorOutputType.THUMBNAIL)!.filePath;

    const thumbnailObjectKey = await uploadFile(
      updatedDraftTemplate.project.id,
      thumbnailFilePath,
      DataCategory.Outputs,
      'image/bmp',
    );
    // upload the input dataset
    const zipFilePath = await compressFolder(updatedDraftTemplate.topLevelFolder);
    const datasetObjectKey = await uploadFile(updatedDraftTemplate.project.id, zipFilePath, DataCategory.Inputs);
    //Delete zipfle
    await deleteFile(zipFilePath);

    let codeBlockInfo: UploadContentResult | undefined = undefined;
    if (updatedDraftTemplate.codeBlocksWorkspace) {
      codeBlockInfo = await uploadContentAsFile(
        updatedDraftTemplate.project.id,
        JSON.stringify(updatedDraftTemplate.codeBlocksWorkspace),
        getUUID(),
        'json',
        DataCategory.CodeBlocksWorkspace,
      );

      // add temp file path to array, so that we can clean it after publishing
      tempFiles.push(codeBlockInfo.filePath);
    }

    let rulesInfo: UploadContentResult | undefined = undefined;
    if (updatedDraftTemplate.rules && updatedDraftTemplate.rules.length > 0) {
      rulesInfo = await uploadContentAsFile(
        updatedDraftTemplate.project.id,
        JSON.stringify(updatedDraftTemplate.rules),
        getUUID(),
        'json',
        DataCategory.Rules,
      );
      // add temp file path to array, so that we can clean it after publishing
      tempFiles.push(rulesInfo.filePath);
    }
    // Inventor application version
    const engineVersion = await getEngineVersion();
    // POST product
    const dynamicContentProduct: DynamicContentProduct = draftToDCTemplate(
      updatedDraftTemplate,
      thumbnailObjectKey,
      datasetObjectKey,
      engineVersion,
      codeBlockInfo?.objectKey,
      rulesInfo?.objectKey,
    );

    // remove unexpected fields, otherwise validation will fail
    const { tenancyId, contentId, ...postProductPayload } = dynamicContentProduct;
    const publishedProduct = await postProduct(tenancyId, postProductPayload);

    // POST variant
    try {
      const inputs = dynamicContentInputsToVariantInputs(publishedProduct.inputs);
      const outputs: PostVariantOutput[] = [];

      // add RFA output if this was requested
      const rfaOutput = publishedProduct.outputs.find((output) => output.type === 'RFA');

      if (rfaOutput) {
        const rfaFilePath = outputFiles.find((x) => x.type === InventorOutputType.RFA)!.filePath;
        const rfaObjectKey = await uploadFile(updatedDraftTemplate.project.id, rfaFilePath!, DataCategory.Outputs);

        outputs.push({
          type: 'RFA',
          urn: rfaObjectKey,
          modelState: rfaOutput.options!.modelStates![0],
        });
      }

      const postVariantPayload: PostVariantPayload = {
        inputs,
        outputs,
      };

      await postVariantToAPI(publishedProduct.tenancyId, publishedProduct.contentId!, postVariantPayload);
    } catch (postVariantError: unknown) {
      // an error in POST variant won't cause publishing to fail
      logError(postVariantError);
    }

    // Clean up the outputs
    await cleanupFiles(tempFiles);

    return {
      status: PublishStatus.COMPLETE,
      publishedProduct,
    };
  } catch (err) {
    logError(err);

    // TODO: this is unused atm; for now just track the error that caused publishing to fail
    const errorMessage = err instanceof Error ? (err as Error).message : String(err);

    // Clean up the outputs
    await cleanupFiles(tempFiles);

    return {
      status: PublishStatus.FAILURE,
      errorMessage,
    };
  }
};
