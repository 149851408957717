import text from 'inventor.text.json';
import {
  checkAndGenerateThumnailInBase64,
  createFullPath,
  FileFilter,
  getPathSeparator,
  getThumbnailImgPath,
  selectFile,
  selectFolder,
} from 'mid-addin-lib';
import {
  NotificationContext,
  NOTIFICATION_STATUSES,
  useAsyncFetchDataWithArgs,
  useLogAndShowNotification,
} from 'mid-react-common';
import { EmptyTopLevelError, logError, ThumbnailError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import TabProgressContext from '../../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../../types';
import { SourceModelData } from './SourceContent.types';

interface UseSourceContentState {
  isPreviewStep: boolean;
  isPreviewLoading: boolean;
  localTopLevelFolder: string;
  localRelativeInventorProjectPath: string;
  localRelativeAssemblyPath: string | undefined;
  localFullAssemblyPath: string;
  thumbnailInBase64: { name: string; base64: string } | null;
  handleSelectTopLevelFolderClick: () => Promise<void>;
  handleSelectInventorProjectClick: () => Promise<void>;
  handleSelectTopLevelAssemblyClick: () => Promise<void>;
  handleNextButtonClick: () => Promise<void>;
}

export const useSourceContent = (): UseSourceContentState => {
  const { currentDraft, setCurrentDraftName, setCurrentDraftSourceModel } = useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const { showNotification } = useContext(NotificationContext);

  const { topLevelFolder, inventorProject, assembly, thumbnail } = currentDraft;

  const [localTopLevelFolder, setLocalTopLevelFolder] = useState(topLevelFolder);
  const [localRelativeInventorProjectPath, setLocalRelativeInventorProjectPath] = useState(inventorProject);
  const [localRelativeAssemblyPath, setLocalRelativeAssemblyPath] = useState(assembly);
  const [localFullAssemblyPath, setLocalFullAssemblyPath] = useState('');
  const [isPreviewStep, setPreviewStep] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [fetchDependencyList, setFetchDependencyList] = useState<string[] | undefined>();
  const { data: thumbnailInBase64, error: thumbnailError } = useAsyncFetchDataWithArgs<{
    name: string;
    base64: string;
  }>(checkAndGenerateThumnailInBase64, fetchDependencyList);

  useLogAndShowNotification(thumbnailError, text.notificationThumbnailFailed);

  useEffect(() => {
    if (thumbnail && localFullAssemblyPath) {
      setFetchDependencyList([thumbnail, localFullAssemblyPath]);
    }
  }, [thumbnail, localFullAssemblyPath]);

  const sourceContentTabProgress = tabProgress[TABS.SOURCE_CONTENT];

  const handleUpdateDataStore = (data: SourceModelData) => {
    const separator = getPathSeparator(data.topLevelFolder || localTopLevelFolder);
    const assemblyPathList = data.assembly?.split(separator) || [];
    const name = assemblyPathList[assemblyPathList.length - 1].replace('.ipt', '').replace('.iam', '');
    setCurrentDraftName(name);
    setCurrentDraftSourceModel(data.topLevelFolder, data.inventorProject, data.assembly, data.thumbnail || '');
  };

  // Determine which view to show
  useEffect(() => {
    const sourceContentFormIsComplete = sourceContentTabProgress === TabProgress.COMPLETED;
    setPreviewStep(sourceContentFormIsComplete);
  }, [sourceContentTabProgress]);

  // Update local state with currentDraft values
  useEffect(() => {
    setLocalTopLevelFolder(topLevelFolder);
    setLocalRelativeInventorProjectPath(inventorProject);
    setLocalRelativeAssemblyPath(assembly);
    if (topLevelFolder && assembly) {
      setLocalFullAssemblyPath(createFullPath(topLevelFolder, assembly));
    }
  }, [topLevelFolder, inventorProject, assembly, thumbnail]);

  const handleSelectTopLevelFolderClick = async () => {
    const folder = await selectFolder();
    // TODO: Add notification if selectFolder returns empty string
    if (folder) {
      setLocalTopLevelFolder(folder);
    }
  };

  const extractRelativePath = (fullPath: string): string | undefined => fullPath.replace(localTopLevelFolder, '');

  const handleSelectInventorProjectClick = async () => {
    const ipjFilter: FileFilter[] = [{ name: 'Project Files(*.ipj)', expression: '*.ipj' }];
    const inventorProject = await selectFile(ipjFilter, false, localTopLevelFolder);
    if (inventorProject.length) {
      const filename = extractRelativePath(inventorProject[0]);
      if (filename) {
        setLocalRelativeInventorProjectPath(filename);
      }
    }
  };

  const handleSelectTopLevelAssemblyClick = async () => {
    const inventorFilesFilter: FileFilter[] = [
      { name: 'Assembly Files (*.iam)', expression: '*.iam' },
      { name: 'Part Files (*.ipt)', expression: '*.ipt' },
    ];
    const assembly = await selectFile(inventorFilesFilter, false, localTopLevelFolder);
    if (assembly.length) {
      const filename = extractRelativePath(assembly[0]);
      if (filename) {
        setLocalRelativeAssemblyPath(filename);
        setLocalFullAssemblyPath(assembly[0]);
      }
    }
  };

  const handleNextButtonClick = async () => {
    try {
      // If topLevelFolder or Assembly are missing, something is wrong
      if (!localFullAssemblyPath) {
        const errorMsg = text.notificationTopLevelFolderAndAssemblyEmpty;
        throw new EmptyTopLevelError(errorMsg);
      }

      setIsPreviewLoading(true);
      // Fetch thumbnail image from API services
      const thumbnailPath = await getThumbnailImgPath(localFullAssemblyPath);
      // If thumbnail request failed, show notification
      if (!thumbnailPath) {
        throw new ThumbnailError(text.notificationThumbnailFailed, {});
      }
      // Set current draft template
      handleUpdateDataStore({
        topLevelFolder: localTopLevelFolder,
        inventorProject: localRelativeInventorProjectPath,
        assembly: localRelativeAssemblyPath,
        ...(thumbnailPath && { thumbnail: thumbnailPath }),
      });
    } catch (err) {
      logError(err);

      if (err instanceof ThumbnailError) {
        showNotification({
          message: text.notificationThumbnailFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else if (err instanceof EmptyTopLevelError) {
        showNotification({
          message: text.notificationTopLevelFolderAndAssemblyEmpty,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        showNotification({
          message: text.notificationProceedWithPreviewFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    } finally {
      setIsPreviewLoading(false);
    }
  };

  return {
    localTopLevelFolder,
    localRelativeInventorProjectPath,
    localRelativeAssemblyPath,
    localFullAssemblyPath,
    isPreviewStep,
    thumbnailInBase64,
    isPreviewLoading,
    handleSelectTopLevelFolderClick,
    handleSelectInventorProjectClick,
    handleSelectTopLevelAssemblyClick,
    handleNextButtonClick,
  };
};

export default useSourceContent;
