import Tooltip from '@adsk/alloy-react-tooltip';
import text from 'inventor.text.json';
import { ThemedIconWrapper } from 'mid-react-common';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { TabProgress } from '../../types';
import { MIDTabCompletedIcon, MIDTabPartiallyCompletedIcon, MIDTabTooltipWrapper, StyledMIDTab } from './MIDTabs.styles';

export interface MIDTabProps {
  children: ReactElement<any, string | JSXElementConstructor<any>> | string;
  label: string;
  id: string;
  tabProgress: TabProgress;
  disabled?: boolean;
  className?: string;
  handleTabClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

interface TabProgressIconsMap {
  // have to specify typeof the icon, because the icon comes from Alloy, but the styled component comes from MUI
  // there is a discrepancy in types
  // TODO: refactor it once moving to MUI
  [TabProgress.COMPLETED]: typeof MIDTabCompletedIcon;
  [TabProgress.PARTIALLY_COMPLETED]: typeof MIDTabPartiallyCompletedIcon;
  [TabProgress.EMPTY]: typeof MIDTabPartiallyCompletedIcon;
}
interface TooltipContentMap {
  [TabProgress.COMPLETED]: string;
  [TabProgress.PARTIALLY_COMPLETED]: string;
  [TabProgress.EMPTY]: string;
}

const MIDTab: React.FC<MIDTabProps> = ({ children, label, id, tabProgress, disabled, className, handleTabClick }) => {
  const tabProgressIconsMap: TabProgressIconsMap = {
    [TabProgress.COMPLETED]: MIDTabCompletedIcon,
    [TabProgress.PARTIALLY_COMPLETED]: MIDTabPartiallyCompletedIcon,
    [TabProgress.EMPTY]: MIDTabPartiallyCompletedIcon,
  };
  const tooltipContentMap: TooltipContentMap = {
    [TabProgress.COMPLETED]: text.tabRequiredFieldsCompleted,
    [TabProgress.PARTIALLY_COMPLETED]: text.tabRequiredFieldsMissing,
    [TabProgress.EMPTY]: text.tabRequiredFieldsMissing,
  };

  const TabProgressIcon = tabProgressIconsMap[tabProgress];

  return (
    <StyledMIDTab label={label} id={id} data-testid={`midtab-${id}`} className={className} onClick={handleTabClick}>
      {children}
      {!disabled && (
        <MIDTabTooltipWrapper>
          <Tooltip content={tooltipContentMap[tabProgress]}>
            <ThemedIconWrapper>
              <TabProgressIcon fr={0} />
            </ThemedIconWrapper>
          </Tooltip>
        </MIDTabTooltipWrapper>
      )}
    </StyledMIDTab>
  );
};

export default MIDTab;
