import Button from '@adsk/alloy-react-button';
import text from 'inventor.text.json';
import { LeftSection, RightButton, RightSection, Subtitle } from 'mid-react-common';
import React from 'react';

const SelectDataset: React.FC<{ handleOpenSavedDraftsClick: () => void }> = ({
  handleOpenSavedDraftsClick,
}): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.subHeaderTitleSelectDataset}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton onClick={handleOpenSavedDraftsClick} size={Button.SIZES.SMALL}>
        {`${text.buttonSavedDrafts}`}
      </RightButton>
    </RightSection>
  </>
);

export default SelectDataset;
