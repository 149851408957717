import Accordion from '@adsk/alloy-react-accordion';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const OutputContainer = styled('fieldset')`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary.charcoal100};
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const OutputRow = styled('div')`
  display: flex;
  justify-content: flex-start;
`;

export const OutputHeader = styled('div')`
  padding: ${({ theme }) => `${2 * theme.var.paddingBase}px`} ${({ theme }) => `${theme.var.paddingBase}px`}
    ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${2 * theme.var.paddingBase}px`};
  width: 50%;
`;

export const OutputInputControl = styled('div')`
  padding: 0 ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${theme.var.paddingBase}px`}
    ${({ theme }) => `${2 * theme.var.paddingBase}px`};
  width: 50%;
`;

export const OutputSmallText = styled('small')`
  padding: 0 ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${theme.var.paddingBase}px`}
    ${({ theme }) => `${2 * theme.var.paddingBase}px`};
`;

export const RadioGroupWrapper = styled(RadioGroup)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const RadioButtonWrapper = styled('div')`
  display: flex;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${theme.var.paddingBase}px`}
    ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${2 * theme.var.paddingBase}px`};
`;

export const RadioButtonField = styled('div')`
  display: flex;
  width: 50%;
  align-items: center;
`;

export const RadioButtonControl = styled(Radio)`
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ToggleControl = styled(Switch)`
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const AccordianItemLabel = styled('div')`
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  margin: 4px;
  width: 40px;
  text-align: center;
`;

export const AccordianItemLabelGroup = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const AccordionWrap = styled(Accordion)`
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.outputsAccordionHeight}px`}
  );
  overflow: scroll;
`;

export const OutputRowHeader = styled('h4')`
  margin: 0px;
`;
