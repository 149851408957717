import Button from '@adsk/alloy-react-button';
import FormLabel from '@mui/material/FormLabel';
import text from 'inventor.text.json';
import { SourceContentFormField } from 'mid-react-common';
import React from 'react';
import { SourceContentRightButton, SourceContentTextInput, SourceInputContainer } from '../SourceContentTab.styles';

interface IPJSectionProps {
  iPJ: string;
  handleSelectInventorProjectClick: () => Promise<void>;
}

const IPJSection: React.FC<IPJSectionProps> = ({ iPJ, handleSelectInventorProjectClick }): JSX.Element => (
  <SourceContentFormField>
    <FormLabel>{text.sourceContentIPJLabel}</FormLabel>
    <SourceInputContainer>
      <SourceContentTextInput
        fullWidth
        size="small"
        inputProps={{ readOnly: true, 'data-testid': 'ipj-input' }}
        type="text"
        placeholder={text.NotSelectedLabel}
        value={iPJ}
      />
      {iPJ ? (
        <SourceContentRightButton
          data-testid="ipj-replace-button"
          onClick={handleSelectInventorProjectClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="ipj-select-button"
          onClick={handleSelectInventorProjectClick}
          variant={Button.VARIANTS.PRIMARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </SourceInputContainer>
  </SourceContentFormField>
);

export default IPJSection;
