import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import text from 'inventor.text.json';
import { FieldSetContainer, FieldsetRow, RowItem } from 'mid-react-common';
import React from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';
import { InputWrapper, NumericFieldsWrapper, TextFieldWrapper } from './NumericInputSection.styles';
import { useNumericInput } from './useNumericInput';

interface NumericInputSectionProps {
  defaultValue: string;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  min: number | string;
  max: number | string;
  increment: number | string;
  visible: boolean;
  readOnly: boolean;
}

const NumericInputSection: React.FC<NumericInputSectionProps> = ({
  defaultValue,
  callInputDataStoreUpdateHandler,
  min,
  max,
  increment,
  visible,
  readOnly,
}) => {
  const {
    localMin,
    localMax,
    localIncrement,
    maxTooltipContent,
    minTooltipContent,
    incrementTooltipContent,
    handleMinimumValueChange,
    handleMaximumValueChange,
    handleIncrementChange,
    handleBlur,
  } = useNumericInput({
    callInputDataStoreUpdateHandler,
    min,
    max,
    increment,
    readOnly,
    defaultValue,
  });
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });
  const isInputDisabled = localReadOnly || !localShowInputInForm;

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <TextFieldWrapper size="small" label={text.inputLabelDefaultValue} type="text" disabled value={defaultValue} />
        </RowItem>
        <NumericFieldsWrapper>
          <InputWrapper>
            <TextFieldWrapper
              size="small"
              type={isInputDisabled ? 'text' : 'number'}
              label={text.inputLabelMinimumValue}
              value={localMin}
              onChange={handleMinimumValueChange}
              disabled={isInputDisabled}
              onBlur={handleBlur}
            />
            {minTooltipContent && (
              <Tooltip title={minTooltipContent} placement="bottom">
                <WarningIcon />
              </Tooltip>
            )}
          </InputWrapper>
          <InputWrapper>
            <TextFieldWrapper
              size="small"
              type={isInputDisabled ? 'text' : 'number'}
              label={text.inputLabelMaximumValue}
              value={localMax}
              onChange={handleMaximumValueChange}
              disabled={isInputDisabled}
              onBlur={handleBlur}
            />
            {maxTooltipContent && (
              <Tooltip title={maxTooltipContent} placement="bottom">
                <WarningIcon />
              </Tooltip>
            )}
          </InputWrapper>
          <InputWrapper>
            <TextFieldWrapper
              size="small"
              label={text.inputLabelIncrementedBy}
              type={isInputDisabled ? 'text' : 'number'}
              value={localIncrement}
              onChange={handleIncrementChange}
              disabled={isInputDisabled}
              onBlur={handleBlur}
            />
            {incrementTooltipContent && (
              <Tooltip title={incrementTooltipContent} placement="bottom">
                <WarningIcon />
              </Tooltip>
            )}
          </InputWrapper>
        </NumericFieldsWrapper>
      </FieldsetRow>
    </FieldSetContainer>
  );
};

export default NumericInputSection;
