import { debounce, isUndefined } from 'lodash';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';
import { TemplateInputType } from 'mid-types';
import { isDraftTemplateIProperty } from 'mid-utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { CallInputDataStoreUpdateHandlerArgs } from '../../../../types';
import { HandleSelectedInputUpdateType } from '../useInputsTab';

export interface UseEditInputsState {
  selectedInputs: DraftTemplateInput[];
  selectedInput: DraftTemplateInput | null;
  handleChangeSelectedInputs: () => void;
  handleInputSelection: (input: DraftTemplateInput) => void;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  isCodeblocksWorkspaceUndefined: boolean;
  setCodeblocksWorkspaceToUndefined: () => void;
}
interface useEditInputsArgs {
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

export const useEditInputs = ({
  setIsEditStep,
  handleSelectedInputDataStoreUpdate,
}: useEditInputsArgs): UseEditInputsState => {
  const { currentDraft, setCurrentDraftCodeBlocksWorkspace } = useContext(DataContext);

  const addedInputs = currentDraft.inputs;
  const selectedInputs: DraftTemplateInput[] = currentDraft.inputs;

  const [selectedInput, setSelectedInput] = useState<DraftTemplateInput | null>(currentDraft.inputs[0] ?? null);

  const _callDebounceInputValueUpdate = useMemo(
    () =>
      debounce(
        (
          input: DraftTemplateInput,
          property: {
            [key: string]: any;
          },
        ) => {
          // Update dataStore
          handleSelectedInputDataStoreUpdate(input, property);

          // To update the selected input in the UI
          setSelectedInput({ ...input, ...property });
        },
        300,
      ),
    [handleSelectedInputDataStoreUpdate],
  );

  // We cancel any pending debounces
  // when the component unmounts to prevent
  // stale updates
  useEffect(
    () => () => {
      _callDebounceInputValueUpdate.cancel();
    },
    [_callDebounceInputValueUpdate],
  );

  const handleChangeSelectedInputs = () => {
    setIsEditStep(false);
  };

  const handleInputSelection = (input: DraftTemplateInput) => {
    const isIProperty = isDraftTemplateIProperty(input);
    const inputIdentifierKey = (isIProperty ? 'id' : 'name') as keyof DraftTemplateInput;
    const inputIdentifierValue = input[inputIdentifierKey];

    const selectedInput = addedInputs.find((input) => input[inputIdentifierKey] === inputIdentifierValue);
    if (selectedInput) {
      setSelectedInput(selectedInput);
    }
  };

  const callInputDataStoreUpdateHandler = (property: { [key: string]: any }, shouldDebounceUpdate?: boolean) => {
    if (!selectedInput) {
      return;
    }

    if (selectedInput?.type !== TemplateInputType.IProperty) {
      if (shouldDebounceUpdate) {
        _callDebounceInputValueUpdate(selectedInput, property);
      } else {
        handleSelectedInputDataStoreUpdate(selectedInput, property);
        setSelectedInput({ ...selectedInput, ...property });
      }
      // If is iProperty selected, the only field that can be updated is `label`.
      // So it needs to be debounced.
    } else if (selectedInput?.type === TemplateInputType.IProperty) {
      _callDebounceInputValueUpdate(selectedInput, property);
    }
  };

  const setCodeblocksWorkspaceToUndefined = () => {
    setCurrentDraftCodeBlocksWorkspace(undefined);
  };

  return {
    selectedInputs,
    selectedInput,
    handleChangeSelectedInputs,
    handleInputSelection,
    callInputDataStoreUpdateHandler,
    isCodeblocksWorkspaceUndefined: isUndefined(currentDraft.codeBlocksWorkspace),
    setCodeblocksWorkspaceToUndefined,
  };
};

export default useEditInputs;
