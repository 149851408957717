import { Selected } from '@adsk/alloy-react-dropdown';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const InputsHeaderWrapper = styled('div')`
  height: ${({ theme }) => `${theme.var.largeButtonHeight}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const InputsFilterRenderItem = styled(Selected)`
  display: flex;
  align-items: center;
`;

export const InputsFilterDropDown = styled('div')`
  margin-left: auto;
  padding-right: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const InputsSelectionTable = styled(DataGrid)`
  border: 1px solid ${({ theme }) => `${theme.colors.primary.charcoal300}`};
  height: calc(
    100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.editInputsTableHeight}px`}
  );
  ${({ theme }) => theme.typography.bodyMedium};
`;
