import text from 'inventor.text.json';
import {
  downloadFileFromUrl,
  extractZipFileToFolder,
  getDownloadUrl,
  MetaInfo,
  productTemplateToDraftTemplate,
  saveDraft,
  selectFolder,
} from 'mid-addin-lib';
import {
  initialModalState,
  ModalContext,
  NotificationContext,
  NOTIFICATION_STATUSES,
  useBase64Thumbnail,
} from 'mid-react-common';
import { DynamicContentProduct } from 'mid-types';
import { logError } from 'mid-utils';
import React, { useContext, useState } from 'react';

interface UseTemplateManagementScreenProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
  selectedProduct: DynamicContentProduct | undefined;
  setSelectedProduct: React.Dispatch<React.SetStateAction<DynamicContentProduct | undefined>>;
  handleDeleteProduct: (selectedProduct: DynamicContentProduct) => void;
}

export interface UseTemplateManagementScreenState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  isdownloadInProgress: boolean;
  handleDeleteProductTemplateClick: () => void;
  handleDownloadDraftClick: () => void;
}

export const useTemplateManagementScreen = ({
  selectedAccount,
  selectedProject,
  selectedProduct,
  setSelectedProduct,
  handleDeleteProduct,
}: UseTemplateManagementScreenProps): UseTemplateManagementScreenState => {
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    selectedProduct?.tenancyId,
    selectedProduct?.thumbnail,
  );
  const [isdownloadInProgress, setIsDownloadInProgress] = useState(false);

  const handleDownloadDraftClick = async () => {
    try {
      setIsDownloadInProgress(true);
      if (selectedProduct) {
        const productUrl = await getDownloadUrl(selectedProduct?.tenancyId, selectedProduct?.dataSetLocation);
        const downloadLocation = await downloadFileFromUrl(productUrl);
        const draftLocation = await uncompressAndSaveDraft(downloadLocation);
        if (draftLocation) {
          await generateDraftFromTemplate(selectedProduct, draftLocation);
        }
        showNotification({
          message: text.notificationDownloadDraftSuccess,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });
      }
    } catch (error: unknown) {
      logError(error);
      showNotification({
        message: text.failedToDownloadFile,
        severity: NOTIFICATION_STATUSES.ERROR,
      });

      const errorMessage = error instanceof Error ? error.message : String(error);
      return Promise.reject(errorMessage);
    } finally {
      setIsDownloadInProgress(false);
    }
  };

  const uncompressAndSaveDraft = async (filePath: string) => {
    const folder = await selectFolder();
    if (folder) {
      setIsDownloadInProgress(true);
      const selectedDownloadLocation = await extractZipFileToFolder(filePath, folder);
      return selectedDownloadLocation;
    }
  };

  const generateDraftFromTemplate = async (product: DynamicContentProduct, selectedDownloadLocation: string) => {
    const newDraftFromProduct = await productTemplateToDraftTemplate(
      selectedAccount,
      selectedProject,
      selectedDownloadLocation,
      product,
    );
    await saveDraft(newDraftFromProduct);
  };

  const _handleDeleteProductTemplateConfirmation = async () => {
    if (selectedProduct) {
      try {
        await handleDeleteProduct(selectedProduct);
        showNotification({
          message: text.deleteTemplateSuccessMessage,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });
        setSelectedProduct(undefined);
      } catch (error: unknown) {
        showNotification({
          message: text.deleteTemplateFailMessage,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }
  };

  const handleDeleteProductTemplateClick = () => {
    setModalState({
      ...initialModalState,
      isOpen: true,
      message: text.deleteTemplateWarningConfirmQuestion,
      onConfirmCallback: _handleDeleteProductTemplateConfirmation,
    });
  };

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    isdownloadInProgress,
    handleDeleteProductTemplateClick,
    handleDownloadDraftClick,
  };
};

export default useTemplateManagementScreen;
