import CheckmarkCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/CheckmarkCircleFilledIcon';
import ContrastIcon from '@adsk/alloy-react-icon/es/lib/ContrastIcon';
import { styled } from '@mui/material/styles';

export const MIDTabsWrapper = styled('ul')`
  list-style: none;
  padding: 0 0 ${({ theme }) => `${theme.var.paddingBase}px`} 0;
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`} ${({ theme }) => `${theme.var.marginBase / 2}px`}
    ${({ theme }) => `${theme.var.marginBase}px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.charcoal200};
`;

export const StyledMIDTab = styled('li')<{ label: string }>`
  display: inline;
  margin-right: ${({ theme }) => `${theme.var.marginBase * 3}px`};
  padding-bottom: ${({ theme }) => `${theme.var.paddingBase}px`};
  cursor: pointer;
  ${({ theme }) => theme.typography.bodyMedium}

  transition: box-shadow 0.3s;

  &.active {
    ${({ theme }) => theme.typography.bodyMediumBold}
    box-shadow: 0px 4px 2px -2px ${({ theme }) => theme.colors.primary.autodeskBlue500};
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.primary.charcoal400};
    cursor: auto;
  }
`;

export const MIDTabTooltipWrapper = styled('div')`
  display: inline-block;
`;

export const MIDTabCompletedIcon = styled(CheckmarkCircleFilledIcon)`
  vertical-align: bottom;
`;

export const MIDTabPartiallyCompletedIcon = styled(ContrastIcon)`
  vertical-align: bottom;
`;
