import text from 'inventor.text.json';
import { useEffect, useState } from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { validateNumericValue } from './NumericInputSection.utils';

export type InputPossibleValues = ((string | number | readonly string[]) & string) | undefined;

interface UseNumericInput {
  localMin: InputPossibleValues;
  localMax: InputPossibleValues;
  localIncrement: InputPossibleValues;
  minTooltipContent?: string;
  maxTooltipContent?: string;
  incrementTooltipContent?: string;
  handleMinimumValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaximumValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleIncrementChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface UseNumericArgs {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  min?: number | string;
  max?: number | string;
  increment?: number | string;
  readOnly: boolean;
  defaultValue?: number | string;
}

export const useNumericInput = ({
  callInputDataStoreUpdateHandler,
  min,
  max,
  increment,
  readOnly,
  defaultValue,
}: UseNumericArgs): UseNumericInput => {
  // localMin, localMax and localIncrement should have the same inferface
  // as min, max and increment(number | string)
  // but due to Alloy TextInput interface, we have problems setting other than InputPossibleValues
  const [localMin, setLocalMinValue] = useState(min as InputPossibleValues);
  const [localMax, setLocalMaxValue] = useState(max as InputPossibleValues);
  const [localIncrement, setLocalIncrementValue] = useState(increment as InputPossibleValues);
  const [minTooltipContent, setMinTooltipContent] = useState<string | undefined>(undefined);
  const [maxTooltipContent, setMaxTooltipContent] = useState<string | undefined>(undefined);
  const [incrementTooltipContent, setIncrementTooltipContent] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (readOnly) {
      setNotAvailableInputValues();
    } else {
      setLocalMinValue(min as InputPossibleValues);
      setLocalMaxValue(max as InputPossibleValues);
      setLocalIncrementValue(increment as InputPossibleValues);
    }
  }, [min, max, increment, readOnly]);

  const setNotAvailableInputValues = () => {
    setLocalMinValue(text.inputValueNotAvailable);
    setLocalMaxValue(text.inputValueNotAvailable);
    setLocalIncrementValue(text.inputValueNotAvailable);
  };

  const handleMinimumValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMinValue(event.target.value);
    const minValue = Number(event.target.value);

    //Allow user to leave the field empty
    if (isNaN(minValue) || event.target.value === '') {
      callInputDataStoreUpdateHandler({ min: undefined }, true);
    } else if (!isNaN(minValue)) {
      callInputDataStoreUpdateHandler({ min: minValue }, true);
    }
  };

  const handleMaximumValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMaxValue(event.target.value);
    const maxValue = Number(event.target.value);

    //Allow user to leave the field empty
    if (isNaN(maxValue) || event.target.value === '') {
      callInputDataStoreUpdateHandler({ max: undefined }, true);
    } else {
      callInputDataStoreUpdateHandler({ max: maxValue }, true);
    }
  };

  const handleIncrementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalIncrementValue(event.target.value);
    const incrementValue = Number(event.target.value);

    //Allow user to leave the field empty
    if (isNaN(incrementValue) || event.target.value === '') {
      callInputDataStoreUpdateHandler({ increment: undefined }, true);
    } else {
      callInputDataStoreUpdateHandler({ increment: incrementValue }, true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = Number(event.target.value);
    const validationResult = validateNumericValue(currentValue, {
      min: localMin === '' ? undefined : Number(localMin),
      max: localMax === '' ? undefined : Number(localMax),
      increment: localIncrement === '' ? undefined : Number(localIncrement),
      defaultValue: Number(defaultValue),
    });

    if (!validationResult.valid) {
      if (validationResult.validationField === 'min') {
        setMinTooltipContent(validationResult.message);
        setLocalMinValue(validationResult.correctedValue?.toString());
        callInputDataStoreUpdateHandler({
          min: validationResult.correctedValue,
        });
      }
      if (validationResult.validationField === 'max') {
        setMaxTooltipContent(validationResult.message);
        setLocalMaxValue(validationResult.correctedValue?.toString());
        callInputDataStoreUpdateHandler({
          max: validationResult.correctedValue,
        });
      }
      if (validationResult.validationField === 'increment') {
        setIncrementTooltipContent(validationResult.message);
        setLocalIncrementValue(validationResult.correctedValue?.toString());
        callInputDataStoreUpdateHandler({
          increment: validationResult.correctedValue,
        });
      }
    } else {
      setMaxTooltipContent(undefined);
      setMinTooltipContent(undefined);
      setIncrementTooltipContent(undefined);
    }
  };

  return {
    localMin,
    localMax,
    localIncrement,
    maxTooltipContent,
    minTooltipContent,
    incrementTooltipContent,
    handleMinimumValueChange,
    handleMaximumValueChange,
    handleIncrementChange,
    handleBlur,
  };
};
