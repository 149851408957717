import Button from '@adsk/alloy-react-button';
import Blockly from 'blockly';
import '@blockly/block-plus-minus';
import text from 'inventor.text.json';
import { isUndefined } from 'lodash';
import { convertDCInputstoDraftTemplateInputs, createFullPath, draftInputsToInventorInputs, HostApi } from 'mid-addin-lib';
import { DraftTemplateInput, DraftTemplateInputParameter } from 'mid-addin-lib/interfaces/templates';
import { codeRunner, NOTIFICATION_STATUSES, NotificationContext, ProductCustomizationForm } from 'mid-react-common';
import { DynamicContentInput, TemplateInputType } from 'mid-types';
import { logError } from 'mid-utils';
import { useContext, useEffect, useRef, useState } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import { BlocklyPlaceholder, BlocklyWrapper, ControlsWrapper, PreviewPlaceholder } from './BlocklyModule.styles';
import useBlocklyModule from './hooks/useBlocklyModule';
import useFormResizeObserver from './hooks/useFormResizeObserver';
import useGenerateBlocklyState from './hooks/useGenerateBlocklyState';

declare let hostApi: HostApi;

const BlocklyModule: React.FC = (): JSX.Element => {
  const { currentDraft } = useContext(DataContext);
  const inputState = useGenerateBlocklyState(currentDraft.inputs);
  const { ref, getCode, blocklyWorkspace } = useBlocklyModule({
    initialState: currentDraft.codeBlocksWorkspace || inputState,
    shouldCleanupBlocks: isUndefined(currentDraft.codeBlocksWorkspace),
  });
  const [inputs, setInputs] = useState<DraftTemplateInput[]>(currentDraft.inputs);
  const { showNotification } = useContext(NotificationContext);

  const formRef = useRef<HTMLDivElement>(null);
  const formWidth = useFormResizeObserver(formRef);

  //Update blockly workspace when form width changes
  useEffect(() => {
    if (blocklyWorkspace) {
      Blockly.svgResize(blocklyWorkspace);
    }
  }, [blocklyWorkspace, formWidth]);

  const runCode = (formInputs: DraftTemplateInput[]) => {
    if (!blocklyWorkspace) {
      logError(text.blocklyWorkspaceNotInitialized);
      showNotification({
        message: text.blocklyWorkspaceNotInitialized,
        severity: NOTIFICATION_STATUSES.WARNING,
      });
      return;
    }
    const blocklyCode = getCode(true);
    const { hasError, errorMessage, result } = codeRunner(blocklyCode, formInputs, blocklyWorkspace);

    if (hasError) {
      showNotification({
        message: errorMessage,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    } else {
      const draftTemplateInputs = convertDCInputstoDraftTemplateInputs(result);
      setInputs(draftTemplateInputs);
    }
  };

  const handleCodeRunnerClick = () => runCode(inputs);

  const handleUpdateModelClick = async () => {
    const documentFilePath = createFullPath(currentDraft.topLevelFolder, currentDraft.assembly);
    const transformedInputs = JSON.stringify(draftInputsToInventorInputs(inputs));

    try {
      await hostApi.openDraftTemplateDocument(documentFilePath, transformedInputs);

      showNotification({
        message: text.inventorModelSuccessfullyUpdated,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err: unknown) {
      showNotification({
        message: text.inventorModelUpdateFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });

      logError(err);
    }
  };

  const handleInputUpdate = (payload: DynamicContentInput) => {
    const updatedInputs = inputs.reduce(
      (updatedFormData: DraftTemplateInput[], inputRow: DraftTemplateInput): DraftTemplateInput[] => {
        if (inputRow.name === payload.name && inputRow.type !== TemplateInputType.IProperty) {
          return [
            ...updatedFormData,
            {
              ...inputRow,
              value: payload.value,
            } as DraftTemplateInputParameter,
          ];
        }

        return [...updatedFormData, inputRow];
      },
      [],
    );
    runCode(updatedInputs);
  };

  return (
    <BlocklyWrapper>
      <BlocklyPlaceholder ref={ref} />
      <PreviewPlaceholder ref={formRef}>
        <ControlsWrapper>
          <Button onClick={handleCodeRunnerClick}>{text.blocklyRunButton}</Button>
          <Button onClick={handleUpdateModelClick} data-testid="update-model">
            {text.inventorUpdateModelButton}
          </Button>
        </ControlsWrapper>
        <ProductCustomizationForm inputs={inputs} handleInputUpdate={handleInputUpdate} />
      </PreviewPlaceholder>
    </BlocklyWrapper>
  );
};

export default BlocklyModule;
