import QuestionCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/QuestionCircleFilledIcon';
import { Column, Table } from '@adsk/alloy-react-table';
import Tooltip from '@adsk/alloy-react-tooltip';
import text from 'inventor.text.json';
import {
  FieldSetContainer,
  FieldsetRow,
  MultiValueList,
  RowItem,
  ThemedIconWrapper,
  TooltipWrapper,
} from 'mid-react-common';
import React from 'react';
import { RuleTableModel } from '../EditInputs.types';

export interface DependencyRulesProps {
  items: string[];
  value: string;
  dependencyRuleTableData: RuleTableModel[];
  dependencyRuleTableColumns: Column<RuleTableModel>[];
  handleSelectControllingInput: (newValue: string | number) => void;
}
const DependencyRules: React.FC<DependencyRulesProps> = ({
  items,
  value,
  dependencyRuleTableColumns,
  dependencyRuleTableData,
  handleSelectControllingInput,
}): JSX.Element => (
  <FieldSetContainer>
    <FieldsetRow>
      <RowItem>
        <b>
          {value !== text.none ? 2 : text.no} {text.inventorNoBooleanDependencyRules}
        </b>
        <TooltipWrapper>
          <Tooltip
            content={
              <>
                <h3>{text.inventorBooleanDependencyRuleTooltipHeader}</h3>
                <p>{text.inventorBooleanDependencyRuleTooltipSummary}</p>
                <p>{text.inventorBooleanDependencyRuleTooltipDetails}</p>
              </>
            }
          >
            <ThemedIconWrapper>
              <QuestionCircleFilledIcon size={17} fr={0} />
            </ThemedIconWrapper>
          </Tooltip>
        </TooltipWrapper>
      </RowItem>
    </FieldsetRow>
    <FieldsetRow>
      <RowItem>
        <MultiValueList
          label={text.inventorControllingParameter}
          items={items}
          value={value}
          onSelectionChange={handleSelectControllingInput}
        />
      </RowItem>
    </FieldsetRow>
    <FieldsetRow>
      <RowItem>
        {value !== text.none && (
          <Table
            columns={dependencyRuleTableColumns as Column<Record<string, unknown>>[]}
            data={dependencyRuleTableData as Record<string, any>[]}
          />
        )}
      </RowItem>
    </FieldsetRow>
  </FieldSetContainer>
);

export default DependencyRules;
