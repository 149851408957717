import text from 'inventor.text.json';
import { ChangeEvent } from 'react';
import CheckboxWithLabelText from '../CheckboxWithLabelText/CheckboxWithLabelText';

export interface ValueConfigurationHeaderProps {
  showInputInForm: boolean;
  readOnly: boolean;
  handleShowInputInFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleReadOnlyChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ValueConfigurationHeader: React.FC<ValueConfigurationHeaderProps> = ({
  showInputInForm,
  readOnly,
  handleShowInputInFormChange,
  handleReadOnlyChange,
}) => (
  <>
    <CheckboxWithLabelText checked={showInputInForm} handleCheckboxChange={handleShowInputInFormChange}>
      {text.inputLabelVisibleInForm}
    </CheckboxWithLabelText>
    {showInputInForm && (
      <CheckboxWithLabelText data-testid="read-only-checkbox" checked={readOnly} handleCheckboxChange={handleReadOnlyChange}>
        {text.inputLabelReadOnly}
      </CheckboxWithLabelText>
    )}
  </>
);
