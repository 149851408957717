import Button from '@adsk/alloy-react-button';
import { styled } from '@mui/material/styles';

export const DraftConfigurationHeaderWrap = styled('div')`
  display: flex;
  height: ${({ theme }) => `${theme.var.draftConfigurationHeaderHeight}px`};
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const LeftSection = styled('div')`
  width: 30%;
`;

export const RightSection = styled('div')`
  margin-left: auto;
`;

export const HeaderButton = styled(Button)`
  margin: 0px ${({ theme }) => `${theme.var.marginBase}px`} 0px 0px;
`;
