import TextField from '@mui/material/TextField';
import text from 'inventor.text.json';
import { FieldSetContainer, FieldsetGridLabel, FieldsetRow, RowItem } from 'mid-react-common';
import React from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';
import { BooleanFieldsetGrid } from './BooleanInputSection.styles';
import useBooleanInput from './useBooleanInput';

interface BooleanInputSectionProps {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  trueLabel: string;
  falseLabel: string;
  visible: boolean;
  readOnly: boolean;
}

const BooleanInputSection: React.FC<BooleanInputSectionProps> = ({
  callInputDataStoreUpdateHandler,
  trueLabel,
  falseLabel,
  visible,
  readOnly,
}) => {
  const { localTrueLabel, localFalseLabel, handleInputTextChange } = useBooleanInput({
    callInputDataStoreUpdateHandler,
    trueLabel,
    falseLabel,
  });
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({
      visible,
      readOnly,
      callInputDataStoreUpdateHandler,
    });

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <BooleanFieldsetGrid>
          <p>{text.inputValues}</p>
          <p>{text.inputsLabel}</p>
          <FieldsetGridLabel>{text.inputLabelBooleanTrue}</FieldsetGridLabel>
          <TextField
            type="text"
            size="small"
            InputProps={{ readOnly: localReadOnly }}
            disabled={localReadOnly}
            name={text.inputLabelBooleanTrue}
            value={localTrueLabel}
            onChange={handleInputTextChange}
            placeholder={text.inputPlaceholderBooleanTrue}
          />

          <FieldsetGridLabel>{text.inputLabelBooleanFalse}</FieldsetGridLabel>
          <TextField
            type="text"
            size="small"
            InputProps={{ readOnly: localReadOnly }}
            disabled={localReadOnly}
            name={text.inputLabelBooleanFalse}
            value={localFalseLabel}
            onChange={handleInputTextChange}
            placeholder={text.inputPlaceholderBooleanFalse}
          />
        </BooleanFieldsetGrid>
      </FieldsetRow>
    </FieldSetContainer>
  );
};

export default BooleanInputSection;
