import Button from '@adsk/alloy-react-button';
import ArrowLeftIcon from '@adsk/alloy-react-icon/es/lib/ArrowLeftIcon';
import text from 'inventor.text.json';
import { LeftButton, LeftSection } from 'mid-react-common';
import React from 'react';

interface PublishLocationScreenHeaderProps {
  handleEditTemplateClick: () => void;
}

export const PublishLocationScreenHeader: React.FC<PublishLocationScreenHeaderProps> = ({ handleEditTemplateClick }) => (
  <LeftSection>
    <LeftButton onClick={handleEditTemplateClick} size={Button.SIZES.SMALL}>
      <ArrowLeftIcon fr={0} />
      {text.buttonEditProductDefinition}
    </LeftButton>
  </LeftSection>
);
