import React from 'react';
import EditInputs from './EditInputs/EditInputsView';
import { useInputsTab } from './useInputsTab';
import InputsSelection from './InputsSelection/InputsSelection';
import { TabContainer } from 'mid-react-common';

const InputsTab: React.FC = (): JSX.Element => {
  const { isEditStep, setIsEditStep, handleSelectedInputDataStoreUpdate } = useInputsTab();

  return (
    <TabContainer>
      {!isEditStep ? (
        <InputsSelection setIsEditStep={setIsEditStep} />
      ) : (
        <EditInputs setIsEditStep={setIsEditStep} handleSelectedInputDataStoreUpdate={handleSelectedInputDataStoreUpdate} />
      )}
    </TabContainer>
  );
};

export default InputsTab;
