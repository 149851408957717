import text from 'inventor.text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DraftTemplate, batchMigrateDeprecatedDrafts, deleteDrafts, getDrafts } from 'mid-addin-lib';
import { BatchDeprecatedDraftMigrationResult } from 'mid-addin-lib/types/drafts';
import { NOTIFICATION_STATUSES, NotificationContext, useAsyncFetchData, useLogAndShowNotification } from 'mid-react-common';
import { logError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import DataContext from '../context/DataStore/Data.context';
import NavigationContext from '../context/NavigationStore/Navigation.context';
import { Screens } from '../context/NavigationStore/navigationStore';

interface UseDraftTemplateScreenProps {
  drafts: DraftTemplate[] | null;
  loading: boolean;
  error: Error | null;
  handleEditTemplateClick: (draftTemplate: DraftTemplate) => void;
  handleDeleteTemplatesClick: (draftIds: string[]) => Promise<void>;
}

const useDraftTemplateScreen = (): UseDraftTemplateScreenProps => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { setCurrentDraft } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);
  const { migrateDeprecatedDraftTemplates } = useFlags();
  const { data: drafts, setData: setDrafts, loading: draftsLoading, error } = useAsyncFetchData<DraftTemplate[]>(getDrafts);
  const [migratingDeprecatedDrafts, setMigratingDeprecatedDrafts] = useState(false);

  useLogAndShowNotification(error, text.notificationGetDraftsFailed);
  const handleEditTemplateClick = (draftTemplate: DraftTemplate) => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
    setCurrentDraft(draftTemplate);
  };

  const handleDeleteTemplatesClick = async (draftIds: string[]) => {
    try {
      const currentDrafts = await deleteDrafts(draftIds);
      setDrafts(currentDrafts);
      showNotification({
        message: text.notificationDeleteDraftSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err) {
      showNotification({
        message: text.notificationDeleteDraftFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      logError(err);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (!drafts?.length) {
      return;
    }

    const updateDeprecatedDraftsAndSave = async (drafts: DraftTemplate[]) => {
      try {
        setMigratingDeprecatedDrafts(true);
        // Convert deprecated drafts to new version
        const { foundDeprecatedDraft, drafts: migratedDrafts }: BatchDeprecatedDraftMigrationResult =
          await batchMigrateDeprecatedDrafts(drafts);
        // Only re-set the drafts state if a deprecated draft is found
        // otherwise you will end up in an infinite loop
        if (foundDeprecatedDraft) {
          setDrafts(migratedDrafts);
        }
      } catch (err) {
        showNotification({
          message: text.failedToMigrateDeprecatedDrafts,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
        logError(err);
      } finally {
        setMigratingDeprecatedDrafts(false);
      }
    };

    if (migrateDeprecatedDraftTemplates) {
      updateDeprecatedDraftsAndSave(drafts);
    }
  }, [drafts, migrateDeprecatedDraftTemplates, setDrafts, showNotification]);

  return {
    drafts,
    loading: draftsLoading || migratingDeprecatedDrafts,
    error,
    handleEditTemplateClick,
    handleDeleteTemplatesClick,
  };
};

export default useDraftTemplateScreen;
