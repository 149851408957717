import QuestionCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/QuestionCircleFilledIcon';
import Tooltip from '@adsk/alloy-react-tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import text from 'inventor.text.json';
import { ThemedIconWrapper, TooltipWrapper } from 'mid-react-common';
import React from 'react';
import {
  OutputHeader,
  OutputRow,
  OutputRowHeader,
  RadioButtonField,
  RadioButtonWrapper,
  RadioGroupWrapper,
} from './OutputsTab.styles';
import { OutputRepresentation } from './OutputsTab.types';

interface OutputRepresentationsProps {
  representations: OutputRepresentation[];
  selectedRepresentations: string[];
  handleRepresentationChange?: (value?: string) => void;
}

const OutputRepresentations: React.FC<OutputRepresentationsProps> = ({
  representations,
  selectedRepresentations,
  handleRepresentationChange,
}) => {
  const handleOutputRepresentationChange = (_: any, value: string | undefined) => {
    handleRepresentationChange && handleRepresentationChange(value);
  };
  const representationControls = representations.map((representation, index) => (
    <RadioButtonWrapper key={index}>
      <RadioButtonField>
        {representation.name}
        {representation.nameInfoTooltip && (
          <TooltipWrapper>
            <Tooltip content={representation.nameInfoTooltip}>
              <ThemedIconWrapper>
                <QuestionCircleFilledIcon size={17} fr={0} />
              </ThemedIconWrapper>
            </Tooltip>
          </TooltipWrapper>
        )}
      </RadioButtonField>
      <RadioButtonField>
        {representation.statusInfoTooltip ? (
          <Tooltip content={representation.statusInfoTooltip}>
            <FormControlLabel
              data-testid={`output-status-${representation.name}`}
              label={representation.status}
              control={<representation.StatusControl />}
              {...representation.statusProps}
            />
          </Tooltip>
        ) : (
          <FormControlLabel
            data-testid={`output-status-${representation.name}`}
            label={representation.status}
            control={<representation.StatusControl />}
            {...representation.statusProps}
          />
        )}
      </RadioButtonField>
    </RadioButtonWrapper>
  ));

  return (
    <>
      <OutputRow>
        <OutputHeader>
          <OutputRowHeader>{text.outputRepresentation}</OutputRowHeader>
        </OutputHeader>
        <OutputHeader>
          <OutputRowHeader>{text.outputStatus}</OutputRowHeader>
        </OutputHeader>
      </OutputRow>
      <OutputRow>
        <RadioGroupWrapper
          value={selectedRepresentations.length > 0 ? selectedRepresentations[0] : ''}
          onChange={handleOutputRepresentationChange}
        >
          {representationControls}
        </RadioGroupWrapper>
      </OutputRow>
    </>
  );
};

export default OutputRepresentations;
