import { styled } from '@mui/material/styles';
import { FieldsetGrid } from 'mid-react-common';

export const BooleanFieldsetGrid = styled(FieldsetGrid)`
  grid-template-columns: 50px 200px;
  grid-template-rows: auto auto auto;

  p {
    margin: 0;
  }
`;
