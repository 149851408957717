import { TableLoadingState } from 'mid-react-common';
import React from 'react';
import InputsHeader from './InputsHeader';
import { InputsSelectionTable } from './InputsSelection.styles';
import { iPropertiesTableColumns, parameterTableColumns } from './constants';
import { useInputsHeader } from './useInputsHeader';
import useInputsSelection from './useInputsSelection';
import { useInputsTable } from './useInputsTable';
import { useTransformToTableIds } from './useTransformToTableIds';

export interface InputsSelectionProp {
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputsSelection: React.FC<InputsSelectionProp> = ({ setIsEditStep }): JSX.Element => {
  const {
    inventorData,
    selectedParameters,
    selectedIProperties,
    setSelectedParameters,
    setSelectedIProperties,
    handleAddInputsButtonClick,
    loading,
  } = useInputsSelection({ setIsEditStep });

  const {
    buttonGroupValue,
    handleOnParameterOriPropertyButtonChange,
    currentFilter,
    setCurrentFilter,
    filterItems,
    isParameter,
  } = useInputsHeader();

  const selectedIds = useTransformToTableIds(isParameter ? selectedParameters : selectedIProperties);
  const totalSelected = selectedParameters.length + selectedIProperties.length;

  const { tableData, onInputSelection, filteredData, renderEmptyState } = useInputsTable({
    initialTableData: inventorData ? (isParameter ? inventorData.parameters : inventorData.iProperties) : null,
    currentFilter,
    isParameter,
    setSelectedParameters,
    setSelectedIProperties,
  });

  return (
    <>
      <InputsHeader
        buttonGroupValue={buttonGroupValue}
        handleOnParameterOriPropertyButtonChange={handleOnParameterOriPropertyButtonChange}
        filterItems={filterItems}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        totalSelected={totalSelected}
        handleAddInputsButtonClick={handleAddInputsButtonClick}
      />

      <InputsSelectionTable
        checkboxSelection
        disableColumnFilter
        initialState={isParameter ? { columns: { columnVisibilityModel: { isKey: false } } } : undefined}
        columns={isParameter ? parameterTableColumns : iPropertiesTableColumns}
        rows={tableData}
        getRowId={(row) => (isParameter ? row['name'] : row['id'])}
        selectionModel={selectedIds}
        onSelectionModelChange={onInputSelection}
        filterModel={filteredData}
        loading={loading}
        components={{
          NoRowsOverlay: renderEmptyState,
          LoadingOverlay: TableLoadingState,
        }}
      />
    </>
  );
};

export default InputsSelection;
