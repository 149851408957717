import { DraftTemplateInputParameter, DraftTemplateIProperty } from 'mid-addin-lib';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';
import { isDraftTemplateIProperty } from 'mid-utils';
import { useContext, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';

export type HandleSelectedParameterUpdateType = (
  input: DraftTemplateInputParameter,
  property: { [key: string]: any },
) => void;

export type HandleSelectedIPropertyUpdateType = (input: DraftTemplateIProperty, property: { [key: string]: any }) => void;

export type HandleSelectedInputUpdateType = (input: DraftTemplateInput, property: { [key: string]: any }) => void;

export interface UseInputsTabState {
  isEditStep: boolean;
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

export const useInputsTab = (): UseInputsTabState => {
  const { updateCurrentDraftParameter, updateCurrentDraftIProperty, currentDraft } = useContext(DataContext);

  const initialEditStep = currentDraft.inputs.length > 0;
  const [isEditStep, setIsEditStep] = useState(initialEditStep);

  const handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType = (input, property) => {
    if (isDraftTemplateIProperty(input)) {
      updateCurrentDraftIProperty(input, property);
    } else {
      updateCurrentDraftParameter(input, property);
    }
  };

  return {
    isEditStep,
    setIsEditStep,
    handleSelectedInputDataStoreUpdate,
  };
};
