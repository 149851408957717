import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  numberOfDraftsSelected?: number;
  draftId?: string | number;
};

export class EditDraftError extends CustomError {
  errorCode = ErrorCode.EditDraftError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
