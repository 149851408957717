import React from 'react';
import { OutputContainer } from './OutputsTab.styles';
import OutputRepresentations from './OutputRepresentations';
import { useBillOfMaterialsOutput } from './useBillOfMaterialsOutput';

const BillOfMaterialsOutput: React.FC = (): JSX.Element => {
  const { localRepresentations, availableRepresentations, handleBillOfMaterialsRepresentationChange } =
    useBillOfMaterialsOutput();
  return (
    <OutputContainer>
      <OutputRepresentations
        representations={availableRepresentations}
        selectedRepresentations={localRepresentations}
        handleRepresentationChange={handleBillOfMaterialsRepresentationChange}
      />
    </OutputContainer>
  );
};

export default BillOfMaterialsOutput;
