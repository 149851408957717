import TriangleDownIcon from '@adsk/alloy-react-icon/es/lib/TriangleDownIcon';
import TriangleUpIcon from '@adsk/alloy-react-icon/es/lib/TriangleUpIcon';
import ListItemIcon from '@mui/material/ListItemIcon';
import { css, styled } from '@mui/material/styles';
import midTheme from 'mid-react-common/global/midTheme';

export const ItemCount = styled('p')`
  // TODO: Can't use var because var includes padding
  height: 20px;
  margin: ${({ theme }) => `${theme.var.marginBase * 1.4}px`};
  ${({ theme }) => theme.typography.bodyMedium}
  text-align: right;
`;

export const EditInputsContainer = styled('form')`
  margin-top: ${({ theme }) => `${theme.var.marginBase}px`};
  overflow: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.editInputsContainerHeight}px`}
  );
`;

export const CodeblocksButtonContainer = styled('div')`
  display: flex;
  margin: 0 ${({ theme }) => `${theme.var.mainMargin}px`};
  justify-content: flex-end;
`;

export const EnableDisableFormWrapper = styled('fieldset')`
  all: inherit;
  ${(props) => props.disabled && disabledFormCSS}
`;

const disabledFormCSS = css`
  opacity: ${midTheme.var.formDisabledOpacity};
  pointer-events: none;
  events: none;
`;

export const MoveUpIcon = styled(TriangleUpIcon)`
  color: ${({ theme }) => theme.colors.primary.black};
`;
export const MoveDownIcon = styled(TriangleDownIcon)`
  color: ${({ theme }) => theme.colors.primary.black};
`;

export const InputWrapper = styled('div')`
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors.primary.charcoal100};
  }
`;

export const ListItemIconWrapper = styled(ListItemIcon, { shouldForwardProp: (prop) => prop !== 'isDragging' })<{
  isDragging?: boolean;
  minwidth?: number;
}>`
  min-width: ${({ minwidth }) => (minwidth ? `${minwidth}px` : 0)};
  ${(props) =>
    !props.isDragging &&
    css(`
        cursor: grab;
      `)}
`;
