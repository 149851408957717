import React from 'react';
import BlocklyModule from '../../BlocklyModule/BlocklyModule';
import { TabContainer } from 'mid-react-common';

const RulesTab: React.FC = (): JSX.Element => (
  <TabContainer>
    <BlocklyModule />
  </TabContainer>
);

export default RulesTab;
