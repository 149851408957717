import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const NumericFieldsWrapper = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 340px;
`;

export const InputWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const TextFieldWrapper = styled(TextField)`
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;
