import Button from '@adsk/alloy-react-button';
import BookmarkFilledIcon from '@adsk/alloy-react-icon/es/lib/BookmarkFilledIcon';
import text from 'inventor.text.json';
import { DraftTemplate, OutputType } from 'mid-addin-lib';
import { MiddleButton, MiddleSection, SummaryTable, SummaryTableRow, TableTitle } from 'mid-react-common';
import { isNotDraftTemplateIProperty } from 'mid-utils';
import React from 'react';
import { CompleteDialog, TemplateSummary } from './Publishing.styles';

export interface PublishingCompleteProps {
  currentDraft: DraftTemplate;
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}

export const PublishingComplete: React.FC<PublishingCompleteProps> = ({
  currentDraft,
  handleOpenSavedDraftsClick,
}): JSX.Element => {
  // Currently only supporting RFA
  const extractRFAOutputType = () => currentDraft.outputs.filter((output) => output.type === OutputType.RFA)[0];
  const parameters = currentDraft.inputs.filter(isNotDraftTemplateIProperty);
  const templateSummaryData: SummaryTableRow[] = [
    {
      title: text.publishTemplateName,
      value: currentDraft.name,
    },
    {
      title: text.publishTemplateTopLevelAssembly,
      value: currentDraft.assembly,
    },
    {
      title: text.publishTemplateParametersCount,
      value: parameters.length.toString(),
    },
    {
      title: text.publishTemplateIPropertiesCount,
      value: (currentDraft.inputs.length - parameters.length).toString(),
    },
    {
      title: text.publishTemplateOutputRepresentation,
      value: extractRFAOutputType().type + ' - ' + extractRFAOutputType().options?.modelStates?.[0],
    },
    {
      title: text.publishTemplateRevitCategory,
      value: extractRFAOutputType().options?.category || '',
    },
    {
      title: text.publishTemplateRevitFamily,
      value: extractRFAOutputType().options?.family || '',
    },
  ];

  const publishLocationData: SummaryTableRow[] = [
    {
      title: text.publishLocationAccount,
      value: currentDraft.account.name,
    },
    {
      title: text.publishLocationProject,
      value: currentDraft.project.name,
    },
    {
      title: text.publishLocationFolder,
      value: currentDraft.folder.name,
    },
  ];

  return (
    <>
      <CompleteDialog>
        <p>
          <strong>{currentDraft.name}</strong> {text.publishingWasPublishedTo}{' '}
          <strong>
            {currentDraft.account.name} / {currentDraft.project.name} / {currentDraft.folder.name}.
          </strong>
        </p>
        <MiddleSection>
          <MiddleButton
            renderIcon={(p) => <BookmarkFilledIcon {...p} />}
            size={Button.SIZES.SMALL}
            onClick={handleOpenSavedDraftsClick}
          >
            {text.buttonSavedDrafts}
          </MiddleButton>
        </MiddleSection>
      </CompleteDialog>
      <TemplateSummary>
        <TableTitle>{text.publishSummaryTableTitle}</TableTitle>
        <SummaryTable data={templateSummaryData} />
      </TemplateSummary>
      <TemplateSummary>
        <TableTitle>{text.publishLocationTableTitle}</TableTitle>
        <SummaryTable data={publishLocationData} />
      </TemplateSummary>
    </>
  );
};
