import { GridColDef } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import { midTheme } from 'mid-react-common';

const MARGINS = midTheme.var.mainMargin * 2 + midTheme.var.marginBase * 2 + midTheme.var.paddingBase * 2;
const CHECKBOX_COLUMN_WIDTH = 52;
const QUARTER_WIDTH = 0.25;
const THIRD_WIDTH = 0.33;

export const parameterTableColumns: GridColDef[] = [
  {
    field: text.inputsTableColumnNameId,
    headerName: text.inputsTableColumnNameHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * QUARTER_WIDTH),
  },
  {
    field: text.inputsTableColumnParameterTypeId,
    headerName: text.inputsTableColumnParameterTypeHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * QUARTER_WIDTH),
  },
  {
    field: text.inputsTableColumnUnitId,
    headerName: text.inputsTableColumnUnitHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * QUARTER_WIDTH),
  },
  {
    field: text.inputsTableColumnValueId,
    headerName: text.inputsTableColumnDefaultValueHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * QUARTER_WIDTH),
  },
  {
    field: text.inputsTableColumnIsKeyId,
    headerName: text.inputsTableColumnIsKeyHeader,
  },
];

export const iPropertiesTableColumns: GridColDef[] = [
  {
    field: text.inputsTableColumnDisplayNameId,
    headerName: text.inputsTableColumnNameHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * (THIRD_WIDTH + 0.01)),
  },
  {
    field: text.inputsTableColumnCategoryId,
    headerName: text.inputsTableColumnCategoryHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * THIRD_WIDTH),
  },
  {
    field: text.inputsTableColumnValueId,
    headerName: text.inputsTableColumnValueHeader,
    width: Math.round((window.innerWidth - MARGINS - CHECKBOX_COLUMN_WIDTH) * THIRD_WIDTH),
  },
];
