import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import text from 'inventor.text.json';
import { DraftTemplateInputParameter } from 'mid-addin-lib';
import { FieldSetContainer, FieldsetRow, RowItem } from 'mid-react-common';
import { InputFormControl } from 'mid-react-common/global/styles/Common.styles';
import useInputLabel from '../../hooks/useInputLabel';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';

export interface ParameterInfoProps {
  parameter: DraftTemplateInputParameter;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
}

export const ParameterInfo: React.FC<ParameterInfoProps> = ({ parameter, callInputDataStoreUpdateHandler }): JSX.Element => {
  const { label, name } = parameter;
  const { localInputLabel, handleLabelChange } = useInputLabel({
    label,
    callInputDataStoreUpdateHandler,
  });

  return (
    <>
      <FieldSetContainer>
        <FieldsetRow>
          <RowItem>
            <InputFormControl width="100%">
              <TextField size="small" label={text.editInputsParameterName} value={name} disabled />
            </InputFormControl>
            <Tooltip title={text.editInputsLabelTooltip} placement="top">
              <InputFormControl width="100%">
                <TextField
                  key={name}
                  size="small"
                  label={text.inputsLabel}
                  value={localInputLabel}
                  onChange={handleLabelChange}
                />
              </InputFormControl>
            </Tooltip>
          </RowItem>
          <RowItem justifyContent="flex-end">
            <InputFormControl width="40%">
              <TextField size="small" value={(parameter as any).unit || ''} label={text.editInputsParameterUnits} disabled />
            </InputFormControl>
          </RowItem>
        </FieldsetRow>
      </FieldSetContainer>
    </>
  );
};
