import { styled } from '@mui/material/styles';

export const BlocklyPlaceholder = styled('div')`
  height: calc(100vh - ${({ theme }) => `${theme.var.editInputsMenuHeight}px);`};
  width: 100%;
`;

export const BlocklyWrapper = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const PreviewPlaceholder = styled('div')`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.var.paddingBase * 2}px `};
  height: calc(100vh - ${({ theme }) => `${theme.var.editInputsMenuHeight}px);`};
`;

export const ControlsWrapper = styled('div')`
  padding: 0 ${({ theme }) => `${theme.var.paddingBase}px`} ${({ theme }) => `${theme.var.paddingBase}px`};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
