import { ChangeEvent, useEffect, useState } from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';

interface UseValueConfigurationHeaderArgs {
  visible: boolean;
  readOnly: boolean;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
}
export interface UseValueConfigurationHeader {
  localShowInputInForm: boolean;
  handleShowInputInFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
  localReadOnly: boolean;
  handleReadOnlyChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const useValueConfigurationHeader = ({
  visible,
  readOnly,
  callInputDataStoreUpdateHandler,
}: UseValueConfigurationHeaderArgs): UseValueConfigurationHeader => {
  const [localShowInputInForm, setLocalShowInputInForm] = useState<boolean>(true);
  const [localReadOnly, setLocalReadOnly] = useState<boolean>(false);

  useEffect(() => {
    setLocalShowInputInForm(visible);
    setLocalReadOnly(readOnly);
  }, [visible, readOnly]);

  const handleShowInputInFormChange = (event: ChangeEvent<HTMLInputElement>): void => {
    callInputDataStoreUpdateHandler({ visible: event.target.checked });
  };

  const handleReadOnlyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    callInputDataStoreUpdateHandler({ readOnly: event.target.checked });
  };

  return {
    localShowInputInForm,
    localReadOnly,
    handleShowInputInFormChange,
    handleReadOnlyChange,
  };
};
