import { GridSelectionModel } from '@mui/x-data-grid';
import { DraftTemplateIProperty, DraftTemplateInputParameter } from 'mid-addin-lib';
import { TemplateInputType } from 'mid-types';
import { useEffect, useState } from 'react';

export const useTransformToTableIds = (
  items: DraftTemplateInputParameter[] | DraftTemplateIProperty[],
): GridSelectionModel => {
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);

  useEffect(() => {
    const ids: GridSelectionModel = items.map((item) => (item.type !== TemplateInputType.IProperty ? item.name : item.id));
    setSelectedIds(ids);
  }, [items]);

  return selectedIds;
};
