import React from 'react';
import OutputRepresentations from './OutputRepresentations';
import { OutputContainer } from './OutputsTab.styles';
import RevitClassificationSection from './RevitClassificationSection';
import { useRevitFamilyOutput } from './useRevitFamilyOutput';

const RevitFamilyOutput: React.FC = (): JSX.Element => {
  const {
    localRevitCategory,
    localRevitFamily,
    localRepresentations,
    revitFamilyCategoryList,
    availableRepresentations,
    handleOnSelectCategory,
    handleRevitFamilyChange,
    handleRevitFamilyRepresentationChange,
  } = useRevitFamilyOutput();
  return (
    <OutputContainer>
      <OutputRepresentations
        representations={availableRepresentations}
        selectedRepresentations={localRepresentations}
        handleRepresentationChange={handleRevitFamilyRepresentationChange}
      />
      <RevitClassificationSection
        revitFamilyCategoryList={revitFamilyCategoryList}
        selectedCategory={localRevitCategory}
        revitFamily={localRevitFamily}
        handleOnSelectCategory={handleOnSelectCategory}
        handleRevitFamilyChange={handleRevitFamilyChange}
      />
    </OutputContainer>
  );
};

export default RevitFamilyOutput;
