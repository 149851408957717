import { DraftTemplateOutput } from 'mid-addin-lib';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';
import { isNotDraftTemplateIProperty } from 'mid-utils';
import { useEffect, useState } from 'react';
import { TabProgress } from '../../types';
import {
  updateOutputsProgressState,
  updateParametersProgressState,
  updateSourceContentProgressState,
} from './tabProgressStore.utils';

export enum TABS {
  SOURCE_CONTENT = 'SOURCE_CONTENT',
  INPUTS = 'INPUTS',
  RULES = 'RULES',
  OUTPUTS = 'OUTPUTS',
}

export interface TabProgressStore {
  tabProgress: {
    [TABS.SOURCE_CONTENT]: TabProgress;
    [TABS.INPUTS]: TabProgress;
    [TABS.RULES]: TabProgress;
    [TABS.OUTPUTS]: TabProgress;
  };
}

interface UseTabProgressStore {
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  inputs: DraftTemplateInput[];
  outputs: DraftTemplateOutput[];
}

export const useTabProgressStore = ({
  topLevelFolder,
  inventorProject,
  assembly,
  inputs,
  outputs,
}: UseTabProgressStore): TabProgressStore => {
  const [sourceContentProgress, setSourceContentProgress] = useState(TabProgress.EMPTY);
  const [inputsProgress, setInputsProgress] = useState(TabProgress.EMPTY);
  const [rulesProgress, setRulesProgress] = useState(TabProgress.EMPTY);
  const [outputsProgress, setOutputsProgress] = useState(TabProgress.EMPTY);

  // SourceContent Progress State
  useEffect(() => {
    const sourceContentProgressState = updateSourceContentProgressState({
      topLevelFolder,
      inventorProject,
      assembly,
    });
    setSourceContentProgress(sourceContentProgressState);
  }, [topLevelFolder, inventorProject, assembly]);

  // Parameters Progress State
  useEffect(() => {
    const parameters = inputs.filter(isNotDraftTemplateIProperty);
    const parametersProgressState = updateParametersProgressState(parameters);
    setInputsProgress(parametersProgressState);

    // For now, if there is one param, rules is considered completed
    setRulesProgress(parametersProgressState);
  }, [inputs]);

  // Outputs Progress State
  useEffect(() => {
    const outputsProgressState = updateOutputsProgressState(outputs);
    setOutputsProgress(outputsProgressState);
  }, [outputs]);

  return {
    tabProgress: {
      [TABS.SOURCE_CONTENT]: sourceContentProgress,
      [TABS.INPUTS]: inputsProgress,
      [TABS.RULES]: rulesProgress,
      [TABS.OUTPUTS]: outputsProgress,
    },
  };
};
