import { useState, useEffect } from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../types';

interface UseInputLabelProps {
  label: string;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
}

interface UseInputLabelState {
  localInputLabel: string;
  handleLabelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useInputLabel = ({ label, callInputDataStoreUpdateHandler }: UseInputLabelProps): UseInputLabelState => {
  const [localInputLabel, setLocalInputLabel] = useState(label);

  useEffect(() => {
    setLocalInputLabel(label);
  }, [label]);

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const label = event.target.value;
    setLocalInputLabel(label);
    callInputDataStoreUpdateHandler({ label }, true);
  };

  return {
    localInputLabel,
    handleLabelChange,
  };
};

export default useInputLabel;
