import { DraftTemplateReducerMap, OutputType } from 'mid-addin-lib';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';
import { isDraftTemplateIProperty } from 'mid-utils';
import { initialDraftTemplateReducer } from './dataStore';
import { CurrentDraftTemplateActions, CurrentDraftTemplateActionTypes } from './dataStore.actions';

const _inputsArrayToMap = (array: DraftTemplateInput[]) => {
  const map = new Map<string, DraftTemplateInput>();
  array.forEach((item) => {
    if (isDraftTemplateIProperty(item)) {
      map.set(item.id, item);
    } else {
      map.set(item.name, item);
    }
  });
  return map;
};

export const draftTemplateReducer = (
  draftTemplate: DraftTemplateReducerMap,
  action: CurrentDraftTemplateActions,
): DraftTemplateReducerMap => {
  switch (action.type) {
    case CurrentDraftTemplateActionTypes.SET_DRAFT: {
      const { draft } = action.payload;
      const inputsMap = _inputsArrayToMap(draft.inputs);

      return { ...draft, inputs: inputsMap };
    }
    case CurrentDraftTemplateActionTypes.SET_NAME: {
      const { newDraftName } = action.payload;
      return { ...draftTemplate, name: newDraftName };
    }
    case CurrentDraftTemplateActionTypes.SET_SOURCE_MODEL: {
      const { topLevelFolder, inventorProject, assembly, thumbnail } = action.payload;
      return { ...draftTemplate, topLevelFolder, inventorProject, assembly, thumbnail };
    }
    case CurrentDraftTemplateActionTypes.SET_PUBLISH_LOCATION: {
      const { account, project, folder } = action.payload;
      return { ...draftTemplate, account, project, folder };
    }
    case CurrentDraftTemplateActionTypes.SET_RULE: {
      const { rule } = action.payload;
      // TODO: Allow multiple rules per parameter?
      return { ...draftTemplate, rules: [rule] };
    }
    case CurrentDraftTemplateActionTypes.SET_CODE_BLOCKS_WORKSPACE: {
      const { workspaceSerialized } = action.payload;
      return { ...draftTemplate, codeBlocksWorkspace: workspaceSerialized };
    }
    case CurrentDraftTemplateActionTypes.ADD_INPUTS: {
      const { newInputs } = action.payload;
      const inputsMap = _inputsArrayToMap(newInputs);
      return { ...draftTemplate, inputs: inputsMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_PARAMETER: {
      const { parameterToUpdate, updatedValue } = action.payload;
      const clonedInputsMap = new Map(draftTemplate.inputs);
      const updatedParameter = {
        ...parameterToUpdate,
        ...updatedValue,
      };
      clonedInputsMap.set(parameterToUpdate.name, updatedParameter);
      return { ...draftTemplate, inputs: clonedInputsMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_IPROPERTY: {
      const { iPropertyToUpdate, updatedValue } = action.payload;
      const clonedInputsMap = new Map(draftTemplate.inputs);
      const updatedIProperty = {
        ...iPropertyToUpdate,
        ...updatedValue,
      };
      clonedInputsMap.set(iPropertyToUpdate.id, updatedIProperty);
      return { ...draftTemplate, inputs: clonedInputsMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_OUTPUT: {
      const { outputType, updatedValue } = action.payload;
      const nonTypeOutputs = draftTemplate.outputs.filter((output) => output.type !== outputType);

      if (outputType === OutputType.BOM) {
        // return without BOM output if no model state is selected
        if (!updatedValue.options?.modelStates?.length) {
          return { ...draftTemplate, outputs: [...nonTypeOutputs] };
        }
      }
      return { ...draftTemplate, outputs: [...nonTypeOutputs, updatedValue] };
    }
    case CurrentDraftTemplateActionTypes.RESET_DRAFT: {
      return { ...initialDraftTemplateReducer };
    }
    default:
      throw new Error('No Draft Template action found');
  }
};
