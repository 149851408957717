import { useState } from 'react';
import { StateSetter } from 'mid-react-common';

export enum Screens {
  DRAFT_TEMPLATES = 'DRAFT_TEMPLATES',
  DRAFT_CONFIGURATION = 'DRAFT_CONFIGURATION',
  PUBLISHING = 'PUBLISHING',
  MANAGE_TEMPLATES = 'MANAGE_TEMPLATES',
}

export interface NavigationStore {
  currentScreen: Screens;
  setCurrentScreen: StateSetter<Screens>;
}

export const useNavigationStore = (): NavigationStore => {
  const [currentScreen, setCurrentScreen] = useState(Screens.DRAFT_TEMPLATES);

  return {
    currentScreen,
    setCurrentScreen,
  };
};
