import { DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import DataContext from 'context/DataStore/Data.context';
import { DraftTemplateInput } from 'mid-addin-lib';
import { useContext, useState } from 'react';

interface useEditInputsSidebarArgs {
  selectedInputs: DraftTemplateInput[];
  handleInputSelection: (input: DraftTemplateInput) => void;
}

interface useEditInputsSidebarState {
  currentDraggingInput: UniqueIdentifier;
  handleDragStart: (event: DragStartEvent) => void;
  handleInputsDragEnd: (event: DragEndEvent) => void;
  handleInputSelectionHandler: (input: DraftTemplateInput) => () => void;
}

export const useEditInputsSidebar = ({
  selectedInputs,
  handleInputSelection,
}: useEditInputsSidebarArgs): useEditInputsSidebarState => {
  const { addCurrentDraftInputs } = useContext(DataContext);
  const [currentDraggingInput, setCurrentDraggingInput] = useState<UniqueIdentifier>('');

  const handleInputSelectionHandler = (input: DraftTemplateInput) => () => handleInputSelection(input);

  const handleInputsDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = selectedInputs.findIndex((parameter) => parameter.name === active.id);
      const newIndex = selectedInputs.findIndex((parameter) => parameter.name === over.id);
      // Update parameters in data store
      addCurrentDraftInputs(arrayMove(selectedInputs, oldIndex, newIndex));
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setCurrentDraggingInput(event.active.id);
  };

  return {
    currentDraggingInput,
    handleDragStart,
    handleInputsDragEnd,
    handleInputSelectionHandler,
  };
};
