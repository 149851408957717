import text from 'inventor.text.json';
import {
  DraftTemplatePublishResult,
  getAllProducts,
  getProjectFolders,
  MetaInfo,
  MetaInfoPath,
  publishDraftTemplate,
  PublishStatus,
  saveDraft,
} from 'mid-addin-lib';
import {
  NotificationContext,
  NOTIFICATION_STATUSES,
  TreeItem,
  useAsyncFetchDataWithArgs,
  useLogAndShowNotification,
} from 'mid-react-common';
import { DynamicContentProduct, ProjectFolder } from 'mid-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';

interface UsePublishingProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
}
export interface UsePublishingState {
  products: DynamicContentProduct[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeItem: TreeItem | undefined;
  isPublishDisabled: boolean;
  handleSelectFolder: (item: TreeItem, path: MetaInfo[]) => void;
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
  handlePublishClick: () => Promise<void>;
  publishResponse: DraftTemplatePublishResult | undefined;
}

export const usePublishing = ({ selectedAccount, selectedProject }: UsePublishingProps): UsePublishingState => {
  const { currentDraft, resetCurrentDraft, setCurrentDraftPublishLocation, setCurrentDraftPublishStatus } =
    useContext(DataContext);
  const { setCurrentScreen } = useContext(NavigationContext);
  const { showNotification } = useContext(NotificationContext);

  const [rootFoldersTreeItems, setRootFoldersDropdownItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeItem, setSelectedFolderDropdownItem] = useState<TreeItem | undefined>();
  const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [publishResponse, setPublishResponse] = useState<DraftTemplatePublishResult | undefined>();
  const [productsQueryArgs, setProductsQueryArgs] = useState<string[] | undefined>();

  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useAsyncFetchDataWithArgs<DynamicContentProduct[]>(getAllProducts, productsQueryArgs);

  useLogAndShowNotification(productsError, text.notificationGetProductsFailed);

  const [rootFoldersQueryArgs, setRootFoldersQueryArgs] = useState<string[] | undefined>();
  const {
    data: rootFolders,
    loading: rootFoldersLoading,
    error: rootFoldersError,
  } = useAsyncFetchDataWithArgs<ProjectFolder[]>(getProjectFolders, rootFoldersQueryArgs);

  useLogAndShowNotification(rootFoldersError, text.notificationGetRootFolderFailed);

  const handleUpdateDataStore = useCallback(
    (folder: MetaInfoPath) => {
      if (selectedAccount && selectedProject) {
        setCurrentDraftPublishLocation(selectedAccount, selectedProject, folder);
      }
    },
    [selectedAccount, selectedProject, setCurrentDraftPublishLocation],
  );

  useEffect(() => {
    setRootFoldersQueryArgs(selectedProject?.id ? [selectedProject?.id] : undefined);
    setSelectedFolderDropdownItem(undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    const foldersTreeDropdown: TreeItem[] =
      rootFolders?.map((folder) => ({
        id: folder.urn,
        value: folder.urn,
        label: folder.title,
        isExpandable: true,
        children: [],
        path: [],
      })) || [];
    setRootFoldersDropdownItems(foldersTreeDropdown);
  }, [rootFolders]);

  useEffect(() => {
    setProductsQueryArgs(selectedProject?.id ? [selectedProject?.id] : undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    setIsPublishDisabled(!selectedFolderTreeItem);
  }, [selectedFolderTreeItem]);

  const handleSelectFolder = (selectedFolder: TreeItem, path: MetaInfo[]) => {
    if (selectedFolder.id) {
      setSelectedFolderDropdownItem({
        id: selectedFolder.id,
        value: selectedFolder.id,
        label: selectedFolder.label,
        isExpandable: selectedFolder.isExpandable,
      });

      const bimFolder: MetaInfoPath = {
        id: selectedFolder.id,
        name: selectedFolder.label.toString(),
        parentPath: path,
      };
      handleUpdateDataStore(bimFolder);
    }
  };

  const handleNewTemplateClick = () => {
    resetCurrentDraft();
    setCurrentDraftPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
  };

  const handleOpenSavedDraftsClick = () => {
    resetCurrentDraft();
    setCurrentDraftPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.DRAFT_TEMPLATES);
  };

  const handlePublishClick = async () => {
    setCurrentDraftPublishStatus(PublishStatus.LOADING);
    await publishTemplate();
  };

  const publishTemplate = async (): Promise<void> => {
    // We save the currentDraft regardless of the
    // outcome of publish
    saveDraft(currentDraft);
    const response: DraftTemplatePublishResult = await publishDraftTemplate(currentDraft);
    if (response.status.toLowerCase() === PublishStatus.COMPLETE) {
      setCurrentDraftPublishStatus(PublishStatus.COMPLETE);
      showNotification({
        message: text.notificationPublishDraftSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } else if (response.status.toLowerCase() === PublishStatus.FAILURE) {
      showNotification({
        message: text.notificationPublishDraftFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      setPublishResponse(response);
      setCurrentDraftPublishStatus(PublishStatus.FAILURE);
    }
  };

  return {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    isPublishDisabled,
    publishResponse,
    handleSelectFolder,
    handleNewTemplateClick,
    handleOpenSavedDraftsClick,
    handlePublishClick,
    products,
    productsLoading,
    productsError,
  };
};

export default usePublishing;
