import AlertInformationFilledIcon from '@adsk/alloy-react-icon/es/lib/AlertInformationFilledIcon';
import Tooltip from '@adsk/alloy-react-tooltip';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import text from 'inventor.text.json';
import { Dropdown, DropdownItem, ThemedIconWrapper, TooltipWrapper } from 'mid-react-common';
import React from 'react';
import { OutputHeader, OutputInputControl, OutputRow, OutputSmallText } from './OutputsTab.styles';

interface RevitClassificationSectionProps {
  revitFamilyCategoryList: DropdownItem[];
  selectedCategory: DropdownItem | null;
  revitFamily: string;
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RevitClassificationSection: React.FC<RevitClassificationSectionProps> = ({
  revitFamilyCategoryList,
  selectedCategory,
  revitFamily,
  handleOnSelectCategory,
  handleRevitFamilyChange,
}) => {
  const theme = useTheme();
  return (
    <>
      <OutputRow>
        <OutputHeader>
          <strong>{text.outputRevitClassification}</strong>
          <TooltipWrapper>
            <Tooltip content={text.outputRevitClassificationTooltip}>
              <ThemedIconWrapper>
                <AlertInformationFilledIcon size={17} fr={0} />
              </ThemedIconWrapper>
            </Tooltip>
          </TooltipWrapper>
        </OutputHeader>
      </OutputRow>
      <OutputRow>
        <OutputInputControl data-testid="revit-category-list">
          <Dropdown
            elements={revitFamilyCategoryList}
            label={text.revitFamilyCategoryMandatory}
            selectedItem={selectedCategory}
            onSelect={handleOnSelectCategory}
            width={`${theme.var.wideInputControl}px`}
          />
        </OutputInputControl>
        <OutputInputControl>
          <TextField
            fullWidth
            size="small"
            label={text.revitFamilyLabel}
            type="text"
            value={revitFamily}
            onChange={handleRevitFamilyChange}
          />
        </OutputInputControl>
      </OutputRow>
      <OutputRow>
        <OutputSmallText>{text.revitFamilyCategoryMandatoryExplanation}</OutputSmallText>
      </OutputRow>
    </>
  );
};

export default RevitClassificationSection;
