import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

interface CheckboxWithLabelTextProps {
  checked: boolean;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

const CheckboxWithLabelText: React.FC<CheckboxWithLabelTextProps> = ({ checked, handleCheckboxChange, children }) => (
  <FormGroup>
    <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckboxChange} />} label={children} />
  </FormGroup>
);

export default CheckboxWithLabelText;
