import PencilIcon from '@adsk/alloy-react-icon/es/lib/PencilIcon';
import TrashCanIcon from '@adsk/alloy-react-icon/es/lib/TrashCanIcon';
import { debounce } from 'lodash';
import { DraftTemplate } from 'mid-addin-lib';
import { TableLoadingState } from 'mid-react-common';
import React, { useEffect, useState } from 'react';
import text from '../../inventor.text.json';
import {
  DraftSelectionTable,
  DraftTableWrapper,
  DraftTemplateTableHeader,
  DraftTemplateTableSummary,
  TableHeaderButton,
  TableHeaderButtonsWrapper,
} from './DraftTable.styles';
import { getColumns } from './DraftTable.utils';
import useDraftTable from './useDraftTable';

export interface DraftTableProps {
  drafts: DraftTemplate[] | null;
  isFetching: boolean;
  handleEditTemplateClick: (draftTemplate: DraftTemplate) => void;
  handleDeleteTemplatesClick: (draftIds: string[]) => void;
}

const DraftTable: React.FC<DraftTableProps> = ({
  drafts,
  isFetching,
  handleEditTemplateClick,
  handleDeleteTemplatesClick,
}): JSX.Element => {
  const {
    tableData,
    numberOfDraftsSelected,
    handleEditTemplateClickWithContext,
    handleDeleteTemplateClickWithContext,
    onDraftSelection,
    renderEmptyState,
  } = useDraftTable({
    drafts,
    handleEditTemplateClick,
    handleDeleteTemplatesClick,
  });
  const [columns, setColumns] = useState(getColumns());

  useEffect(() => {
    const windowResizeCallback = () => {
      setColumns(getColumns());
    };
    const debouncedWindowResizeCallback = debounce(windowResizeCallback, 300);

    window.addEventListener('resize', debouncedWindowResizeCallback);

    return () => window.removeEventListener('resize', debouncedWindowResizeCallback);
  });

  return (
    <DraftTableWrapper>
      <DraftTemplateTableHeader>
        {drafts && drafts.length > 0 && (
          <DraftTemplateTableSummary>{text.draftTableSummaryProductDefinitions}</DraftTemplateTableSummary>
        )}
        <TableHeaderButtonsWrapper>
          <TableHeaderButton
            disabled={numberOfDraftsSelected !== 1}
            renderIcon={(p) => <PencilIcon {...p} />}
            onClick={handleEditTemplateClickWithContext}
            data-testid="button-edit-product-definition"
          >
            {text.draftTableEdit}
          </TableHeaderButton>
          <TableHeaderButton
            disabled={numberOfDraftsSelected === 0}
            renderIcon={(p) => <TrashCanIcon {...p} />}
            onClick={handleDeleteTemplateClickWithContext}
          >
            {text.draftTableDelete}
          </TableHeaderButton>
        </TableHeaderButtonsWrapper>
      </DraftTemplateTableHeader>
      <DraftSelectionTable
        checkboxSelection
        columns={columns}
        rows={tableData}
        onSelectionModelChange={onDraftSelection}
        getRowId={(row) => row.id}
        loading={isFetching}
        components={{
          NoRowsOverlay: renderEmptyState,
          LoadingOverlay: TableLoadingState,
        }}
      />
    </DraftTableWrapper>
  );
};

export default DraftTable;
