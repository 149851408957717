import React from 'react';
import { DropdownItem } from 'mid-react-common';
import Dropdown from 'mid-react-common/components/Dropdown/Dropdown';
import FunnelIcon from '@adsk/alloy-react-icon/es/lib/FunnelIcon';

export interface InputsFilterProps {
  items: string[];
  initialSelectedItem: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

const InputsFilter: React.FC<InputsFilterProps> = ({ items, initialSelectedItem, setFilter }): JSX.Element => {
  const handleOnSelect = (changes: { selectedItem?: DropdownItem | null }) =>
    setFilter(changes.selectedItem?.value.toString() || '');

  const mapDropdownElement = items.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <Dropdown
      elements={mapDropdownElement}
      selectedItem={{ value: initialSelectedItem, label: initialSelectedItem }}
      controlIcon={FunnelIcon}
      onSelect={handleOnSelect}
    />
  );
};

export default InputsFilter;
