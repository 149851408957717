import { FieldSetContainer, FieldsetRow, MultiValueList, RowItem } from 'mid-react-common';
import React from 'react';
import text from '../../inventor.text.json';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';

export interface MultiValueListSectionProps {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  items: string[] | number[];
  value: string | number | undefined;
  visible: boolean;
  readOnly: boolean;
  disableInput: boolean;
}

export const MultiValueListSection: React.FC<MultiValueListSectionProps> = ({
  callInputDataStoreUpdateHandler,
  items,
  value,
  visible,
  readOnly,
  disableInput,
}) => {
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <MultiValueList
            label={`${text.values} ${items.length}`}
            items={items}
            value={value}
            readOnly={false}
            onSelectionChange={(newValue: string | number) =>
              !disableInput && callInputDataStoreUpdateHandler({ value: newValue })
            }
          />
        </RowItem>
      </FieldsetRow>
    </FieldSetContainer>
  );
};
