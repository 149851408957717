import Button from '@adsk/alloy-react-button';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const DraftTableWrapper = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const DraftTemplateTableHeader = styled('div')`
  display: flex;
  height: ${({ theme }) => `${theme.var.draftHeader}px`};
  flex-direction: row;
  align-items: center;
`;

export const TableHeaderButtonsWrapper = styled('div')`
  margin-left: auto;
`;

export const DraftTemplateTableSummary = styled('h3')`
  padding: ${({ theme }) => `${theme.var.paddingBase}px`} 0 0;
  ${({ theme }) => theme.typography.heading3}
`;

export const TableHeaderButton = styled(Button)`
  margin: 0 0 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const DraftSelectionTable = styled(DataGrid)`
  width: 100%;
  border: 1px solid ${({ theme }) => `${theme.colors.primary.charcoal300}`};
  height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.draftsTableHeight}px`});
  ${({ theme }) => theme.typography.bodyMedium};
`;
