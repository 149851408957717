import { isEqual } from 'lodash';
import {
  DraftTemplate,
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  DraftTemplateOutput,
  DraftTemplateReducerMap,
  InputRule,
  MetaInfo,
  MetaInfoPath,
  OutputType,
  PublishStatus,
  SerializedBlocklyWorkspaceState,
} from 'mid-addin-lib';
import { StateSetter } from 'mid-react-common';
import { useEffect, useReducer, useRef, useState } from 'react';
import { CurrentDraftTemplateActionTypes } from './dataStore.actions';
import { draftTemplateReducer } from './dataStore.reducers';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';

export const initalPublishStatus = PublishStatus.IDLE;

export const initialDraftTemplate: DraftTemplate = {
  id: '',
  name: '',
  lastUpdated: 0,
  account: { id: '', name: '' },
  project: { id: '', name: '' },
  folder: { id: '', name: '', parentPath: [] },
  topLevelFolder: '',
  inventorProject: '',
  assembly: '',
  thumbnail: '',
  inputs: [],
  rules: [],
  outputs: [],
};

export const initialDraftTemplateReducer: DraftTemplateReducerMap = {
  id: '',
  name: '',
  lastUpdated: 0,
  account: { id: '', name: '' },
  project: { id: '', name: '' },
  folder: { id: '', name: '', parentPath: [] },
  topLevelFolder: '',
  inventorProject: '',
  assembly: '',
  thumbnail: '',
  inputs: new Map<string, DraftTemplateInput>(),
  rules: [],
  outputs: [],
};

export interface DataStore {
  draftHasUnsavedChanges: boolean;
  currentDraft: DraftTemplate;
  currentDraftPublishStatus: PublishStatus;
  setCurrentDraftPublishStatus: StateSetter<PublishStatus>;
  setCurrentDraft: (draft: DraftTemplate) => void;
  setCurrentDraftName: (newDraftName: string) => void;
  setCurrentDraftSourceModel: (topLevelFolder: string, inventorProject: string, assembly: string, thumbnail: string) => void;
  setCurrentDraftPublishLocation: (account: MetaInfo, project: MetaInfo, folder: MetaInfoPath) => void;
  setCurrentDraftRule: (rule: InputRule) => void;
  setCurrentDraftCodeBlocksWorkspace: (workspaceSerialized: SerializedBlocklyWorkspaceState | undefined) => void;
  addCurrentDraftInputs: (newParameters: DraftTemplateInput[]) => void;
  updateCurrentDraftParameter: (
    parameterToUpdate: DraftTemplateInputParameter,
    updatedValue: { [key: string]: any },
  ) => void;
  updateCurrentDraftIProperty: (iPropertyToUpdate: DraftTemplateIProperty, updatedValue: { [key: string]: any }) => void;
  updateCurrentDraftOutput: (OutputType: OutputType, updatedValue: DraftTemplateOutput) => void;
  deleteCurrentDraftTable: () => void;
  resetCurrentDraft: () => void;
}

export const useStore = (): DataStore => {
  const [currentDraft, dispatchCurrentDraftAction] = useReducer(draftTemplateReducer, initialDraftTemplateReducer);
  const previousDraftRef = useRef(currentDraft);
  const [draftHasUnsavedChanges, setDraftHasUnsavedChanges] = useState<boolean>(false);
  const [currentDraftPublishStatus, setCurrentDraftPublishStatus] = useState(initalPublishStatus);

  // Unsaved Changes Tracker
  useEffect(() => {
    const isInitialDraftTemplate = isEqual(currentDraft, initialDraftTemplateReducer);
    const isSavedDraft = !!currentDraft.id;

    if (isInitialDraftTemplate) {
      setDraftHasUnsavedChanges(false);
    } else if (!isSavedDraft && !isInitialDraftTemplate) {
      setDraftHasUnsavedChanges(true);
    } else if (isSavedDraft) {
      // If the previous draft's lastUpdated is initialDraftTemplate.lastUpdated
      // and the currentDraft is a saved draft then it means
      // the user has clicked "Edit Draft"
      const initialEditDraftClick = previousDraftRef.current.lastUpdated === initialDraftTemplate.lastUpdated;
      if (initialEditDraftClick) {
        setDraftHasUnsavedChanges(false);
      } else {
        // We need to extract the lastUpdated from draft and then compare
        const { lastUpdated: _prevLastUpdated, ...prevDraftWithoutLastUpdated } = previousDraftRef.current;
        const { lastUpdated: _currentLastUpdated, ...currentDraftWithoutLastUpdated } = currentDraft;
        const hasUnsavedChanges = !isEqual(prevDraftWithoutLastUpdated, currentDraftWithoutLastUpdated);
        setDraftHasUnsavedChanges(hasUnsavedChanges);
      }

      // update the previousDraftRef when a "saved" draft is
      // set to the store (e.g., edit draft) or updated
      previousDraftRef.current = currentDraft;
    }
  }, [currentDraft]);

  const setCurrentDraft = (draft: DraftTemplate) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_DRAFT,
      payload: { draft },
    });
  };

  const setCurrentDraftName = (newDraftName: string) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_NAME,
      payload: { newDraftName },
    });
  };

  const setCurrentDraftSourceModel = (
    topLevelFolder: string,
    inventorProject: string,
    assembly: string,
    thumbnail: string,
  ) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_SOURCE_MODEL,
      payload: { topLevelFolder, inventorProject, assembly, thumbnail },
    });
  };

  const setCurrentDraftPublishLocation = (account: MetaInfo, project: MetaInfo, folder: MetaInfoPath) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_PUBLISH_LOCATION,
      payload: { account, project, folder },
    });
  };

  const addCurrentDraftInputs = (newInputs: DraftTemplateInput[]) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.ADD_INPUTS,
      payload: { newInputs },
    });
  };

  const updateCurrentDraftParameter = (
    parameterToUpdate: DraftTemplateInputParameter,
    updatedValue: { [key: string]: any },
  ) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.UPDATE_PARAMETER,
      payload: { parameterToUpdate, updatedValue },
    });
  };

  const updateCurrentDraftIProperty = (iPropertyToUpdate: DraftTemplateIProperty, updatedValue: { [key: string]: any }) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.UPDATE_IPROPERTY,
      payload: { iPropertyToUpdate, updatedValue },
    });
  };

  const updateCurrentDraftOutput = (outputType: OutputType, updatedValue: DraftTemplateOutput) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.UPDATE_OUTPUT,
      payload: { outputType, updatedValue },
    });
  };

  const setCurrentDraftRule = (rule: InputRule) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_RULE,
      payload: { rule },
    });
  };

  const setCurrentDraftCodeBlocksWorkspace = (workspaceSerialized: SerializedBlocklyWorkspaceState | undefined) => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.SET_CODE_BLOCKS_WORKSPACE,
      payload: { workspaceSerialized },
    });
  };

  const deleteCurrentDraftTable = () => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.DELETE_TABLE,
    });
  };

  const resetCurrentDraft = () => {
    dispatchCurrentDraftAction({
      type: CurrentDraftTemplateActionTypes.RESET_DRAFT,
    });
  };

  return {
    draftHasUnsavedChanges,
    currentDraft: {
      ...currentDraft,
      inputs: [...currentDraft.inputs.values()],
    },
    currentDraftPublishStatus,
    setCurrentDraftPublishStatus,
    setCurrentDraft,
    setCurrentDraftName,
    setCurrentDraftSourceModel,
    setCurrentDraftPublishLocation,
    setCurrentDraftRule,
    setCurrentDraftCodeBlocksWorkspace,
    addCurrentDraftInputs,
    updateCurrentDraftParameter,
    updateCurrentDraftIProperty,
    updateCurrentDraftOutput,
    deleteCurrentDraftTable,
    resetCurrentDraft,
  };
};
