import Button from '@adsk/alloy-react-button';
import BookmarkFilledIcon from '@adsk/alloy-react-icon/es/lib/BookmarkFilledIcon';
import PlusCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/PlusCircleFilledIcon';
import text from 'inventor.text.json';
import { LeftButton, LeftSection } from 'mid-react-common';
import React from 'react';

const SourceContent: React.FC<{
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}> = ({ handleNewTemplateClick, handleOpenSavedDraftsClick }): JSX.Element => (
  <>
    <LeftSection>
      <LeftButton
        renderIcon={(p) => <PlusCircleFilledIcon {...p} />}
        onClick={handleNewTemplateClick}
        size={Button.SIZES.SMALL}
      >
        {text.buttonNewProductDefinition}
      </LeftButton>
      <LeftButton
        renderIcon={(p) => <BookmarkFilledIcon {...p} />}
        onClick={handleOpenSavedDraftsClick}
        size={Button.SIZES.SMALL}
      >
        {text.buttonSavedDrafts}
      </LeftButton>
    </LeftSection>
  </>
);

export default SourceContent;
