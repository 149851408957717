import text from 'inventor.text.json';
import { debounce } from 'lodash';
import { createFullPath, DraftTemplateOutput, getModelStates, OutputType } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import { RadioButtonControl } from './OutputsTab.styles';
import { OutputRepresentation } from './OutputsTab.types';

interface UseBillOfMaterialsOutputState {
  localRepresentations: string[];
  availableRepresentations: OutputRepresentation[];
  handleBillOfMaterialsRepresentationChange: (value?: string) => void;
}

export const useBillOfMaterialsOutput = (): UseBillOfMaterialsOutputState => {
  const { currentDraft, updateCurrentDraftOutput } = useContext(DataContext);
  const [availableRepresentations, setAvailableRepresentations] = useState<OutputRepresentation[]>([]);

  const currentDraftBillOfMaterialsOutput: DraftTemplateOutput | undefined = currentDraft.outputs.find(
    (output) => output.type === OutputType.BOM,
  );

  // Get current Bill Of Materials output representations (modelStates) in draft
  const billOfMaterialsRepresentations = currentDraftBillOfMaterialsOutput?.options?.modelStates ?? [];

  const [localRepresentations, setLocalRepresentations] = useState<string[]>(billOfMaterialsRepresentations);

  const debounceUpdateBillOfMaterialsOutput = debounce(
    (billOfMaterialsOutput: DraftTemplateOutput) => updateCurrentDraftOutput(OutputType.BOM, billOfMaterialsOutput),
    500,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBillOfMaterialsOutputDebounced = useCallback(
    debounceUpdateBillOfMaterialsOutput,
    [], // Creating it only once initially.
  );

  useEffect(() => {
    if (!currentDraftBillOfMaterialsOutput?.options?.modelStates && availableRepresentations.length > 0) {
      // For AU, If no BOM selected in currentDraft, we pre-select the first one available
      // for now. Maybe the requirements will change later and we need to remove
      setLocalRepresentations([availableRepresentations[0].name]);
    }
  }, [currentDraftBillOfMaterialsOutput?.options?.modelStates, availableRepresentations]);

  useEffect(() => {
    const updatedBillOfMaterialsOutput: DraftTemplateOutput = {
      type: OutputType.BOM,
      options: {
        modelStates: localRepresentations,
      },
    };
    onBillOfMaterialsOutputDebounced(updatedBillOfMaterialsOutput);
  }, [localRepresentations, onBillOfMaterialsOutputDebounced]);

  const fetchModelStates = useCallback(async (iamFileFullPath: string) => {
    const modelStates = await getModelStates(iamFileFullPath);

    const representationList: OutputRepresentation[] = modelStates.map((modelState) => ({
      name: modelState,
      status: text.outputAvailable,
      StatusControl: RadioButtonControl,
      statusProps: { value: modelState, disabled: false },
    }));
    setAvailableRepresentations(representationList);
  }, []);

  useEffect(() => {
    const topLevelFolder = currentDraft.topLevelFolder;
    const assembly = currentDraft.assembly;
    if (topLevelFolder && assembly) {
      fetchModelStates(createFullPath(topLevelFolder, assembly));
    }
  }, [currentDraft.assembly, currentDraft.topLevelFolder, fetchModelStates]);

  const handleBillOfMaterialsRepresentationChange = (value?: string) => {
    if (!value) {
      logError('Error: User must select at least one representation for Bill Of Materials output.');
    } else {
      setLocalRepresentations([value]);
    }
  };

  return {
    localRepresentations,
    availableRepresentations,
    handleBillOfMaterialsRepresentationChange,
  };
};
