import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { InputWrapper } from './EditInputs.styles';
import { Input } from './Input';

export const ItemTypes = {
  CARD: 'card',
};

export interface SortableInputProps {
  id: string;
  sortingId: string;
  isSelected: boolean;
  name: string;
  title?: string | undefined;
  handleSelection: (event: React.MouseEvent<HTMLDivElement>) => void;
  renderIcon: () => JSX.Element;
}

export const SortableInput: React.FC<SortableInputProps> = ({
  id,
  name,
  sortingId,
  isSelected,
  handleSelection,
  renderIcon,
}) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({ id: sortingId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <InputWrapper style={style} ref={setNodeRef} data-testid="sidebarInput">
      <Input
        id={id}
        sortingId={sortingId}
        handleSelection={handleSelection}
        isSelected={isSelected}
        name={name}
        renderIcon={renderIcon}
      />
    </InputWrapper>
  );
};
