import Button from '@adsk/alloy-react-button';
import InfoIcon from '@mui/icons-material/Info';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import text from 'inventor.text.json';
import { LabelTextRequired, SourceContentFormField, TooltipWrapper } from 'mid-react-common';
import React from 'react';
import { SourceContentRightButton, SourceContentTextInput, SourceInputContainer } from '../SourceContentTab.styles';

interface TopFolderSectionProps {
  topLevelFolder: string;
  handleSelectTopLevelFolderClick: () => Promise<void>;
}

const TopFolderSection: React.FC<TopFolderSectionProps> = ({
  topLevelFolder,
  handleSelectTopLevelFolderClick,
}): JSX.Element => (
  <SourceContentFormField>
    <FormLabel>
      {text.sourceContentTopLevelFolderLabel}
      <LabelTextRequired>*</LabelTextRequired>
      <TooltipWrapper>
        <Tooltip title={text.sourceContentTopLevelTooltip} placement="top-end">
          <InfoIcon />
        </Tooltip>
      </TooltipWrapper>
    </FormLabel>

    <SourceInputContainer>
      <SourceContentTextInput
        fullWidth
        size="small"
        type="text"
        placeholder={text.NotSelectedLabel}
        inputProps={{ readOnly: true, 'data-testid': 'top-folder-input' }}
        value={topLevelFolder}
      />
      {topLevelFolder ? (
        <SourceContentRightButton
          data-testid="top-folder-replace-button"
          onClick={handleSelectTopLevelFolderClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="top-folder-select-button"
          onClick={handleSelectTopLevelFolderClick}
          variant={Button.VARIANTS.PRIMARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </SourceInputContainer>
  </SourceContentFormField>
);

export default TopFolderSection;
