import Blockly, { BlockSvg } from 'blockly';
import { DraftTemplateInputParameter } from 'mid-addin-lib';
import { TemplateInputType } from 'mid-types';
import { AvailableInputFunctionValues, BlocklyInputParameterDropdownValues } from '../BlocklyModule.types';
import {
  blocklyBlockOptionsByType,
  blocklyConnectingBlock,
  blocklyDropdown,
  blocklyFunctionsDropdown,
  blockTypeByFunctionType,
  blockTypeByInputType,
  customBlocklyExtensions,
  valueKey,
} from '../constants';

/*
 * Blockly extensions are used to add functionality to blocks.
 * These extensions are used to display dynamically generated dropdowns
 * from DraftTemplateInputParameter[].
 */

export const initializeBlocklyExtensions = (inputParameters: DraftTemplateInputParameter[]): void => {
  //When Inputs change, we need to add new dropdown options
  if (Blockly.Extensions.isRegistered(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION)) {
    Blockly.Extensions.unregister(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION);
  }

  /*
   *  This extension is used to dynamically generate dropdowns for Inputs and Functions
   *  It also sets the check type for the block based on the selected option.
   *
   *  The inputs dropdown looks like this:
   *
   *     // [string, BlocklyInputParameterDropdownValues]
   *    [
   *      ['inputParameter1', '{name: "inputParameterName1", type: "Numeric"}'],
   *      ...
   *      ['inputParameter2', '{name: "inputParameterNameN", type: "Boolean"}'],
   *    ]
   *
   *
   * Blockly uses the first parameter to display the dropdown option and the second parameter
   * to store the value of the option. In this case, we are storing the name and type of the
   * input parameter for validation purposes.
   */

  Blockly.Extensions.register(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION, function (this: BlockSvg) {
    // Get the dropdown values from the block
    const inputsDropdown = this.getInput(blocklyDropdown);
    const functionsDropdown = this.getInput(blocklyFunctionsDropdown);

    if (functionsDropdown && inputsDropdown) {
      // Generate the input dropdown values
      const newInputsDropdown = new Blockly.FieldDropdown(() => {
        const inputs = [
          ...inputParameters.map((input) => [input.name, JSON.stringify({ name: input.name, type: input.type })]),
        ];
        return inputs;
      });

      //Run Validator for first time to populate function dropdown
      const inputValue = newInputsDropdown.getValue();
      inputDropdownTypeValidator.call(this, functionsDropdown, inputValue);

      // Set the validator for the input dropdown
      newInputsDropdown.setValidator(inputDropdownTypeValidator.bind(this, functionsDropdown));

      // Append the new dropdown to the block
      inputsDropdown.appendField(newInputsDropdown);
    }
  });
};

// Depending on the selected input, we need to update the Functions dropdown
// to display the correct options. We also need to update the block's validator
function inputDropdownTypeValidator(this: BlockSvg, functionsDropdown: Blockly.Input, inputValue: string) {
  // Get the current input type
  const inputType = getInputType(inputValue);

  // If block has field "connecting_block" ie, accepts connections
  //   then we check for type of the connecting block
  //   otherwise, we set the type of the current block
  const connecting_block = this.getInput(blocklyConnectingBlock);
  if (connecting_block) {
    const updatedFunctionsDropdown = generateFunctionsDropdownAndSetValidator(
      inputType,
      connecting_block.setCheck.bind(connecting_block),
    );

    //Set check for the first time
    connecting_block.setCheck(blockTypeByInputType[inputType]);

    if (this.getField(blocklyFunctionsDropdown)) {
      functionsDropdown.removeField(blocklyFunctionsDropdown);
    }
    functionsDropdown.appendField(updatedFunctionsDropdown, blocklyFunctionsDropdown);
  } else {
    const updatedFunctionsDropdown = generateFunctionsDropdownAndSetValidator(
      inputType,
      this.outputConnection.setCheck.bind(this.outputConnection),
    );

    //Set check for the first time
    this.outputConnection.setCheck(blockTypeByInputType[inputType]);

    if (this.getField(blocklyFunctionsDropdown)) {
      functionsDropdown.removeField(blocklyFunctionsDropdown);
    }
    functionsDropdown.appendField(updatedFunctionsDropdown, blocklyFunctionsDropdown);
  }
}

//Function to parse the input type from the dropdown value
function getInputType(inputValue: string) {
  const currentInput = JSON.parse(inputValue) as BlocklyInputParameterDropdownValues;
  const currentInputType = currentInput.type as TemplateInputType;

  return currentInputType;
}

// This function generates the Functions dropdown based on the input type
// It also sets the check type for the block based on the selected option
function generateFunctionsDropdownAndSetValidator(type: TemplateInputType, setCheck: Function) {
  const updatedFunctionsDropdown = new Blockly.FieldDropdown(() => blocklyBlockOptionsByType[type]);

  updatedFunctionsDropdown.setValidator((newValue: AvailableInputFunctionValues) => {
    const blockType = newValue === valueKey ? blockTypeByInputType[type] : blockTypeByFunctionType[newValue];
    setCheck(blockType);
  });
  return updatedFunctionsDropdown;
}
