import React from 'react';
import { TableTitle } from '../../global/styles/Common.styles';
import text from '../../mid-react-common.text.json';
import SummaryTable, { SummaryTableRow } from '../SummaryTable/SummaryTable';

interface ProductDetailsProps {
  title: string;
  productCategory: string;
  productFamily: string;
  productLevelOfDetail: string;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  productLevelOfDetail,
}): JSX.Element => {
  const productDetailsData: SummaryTableRow[] = [
    {
      title: text.revitCategoryProductDetails,
      value: productCategory,
    },
    {
      title: text.revitFamilyProductDetails,
      value: productFamily,
    },
    {
      title: text.revitLevelOfDetailProductDetails,
      value: productLevelOfDetail,
    },
  ];

  return (
    <>
      <TableTitle>{title}</TableTitle>
      <SummaryTable data={productDetailsData} />
    </>
  );
};
