import Button from '@adsk/alloy-react-button';
import FormLabel from '@mui/material/FormLabel';
import text from 'inventor.text.json';
import { SourceContentFormField } from 'mid-react-common';
import React from 'react';
import { SourceContentRightButton, SourceContentTextInput, SourceInputContainer } from '../SourceContentTab.styles';

interface AssemblySectionProps {
  topLevelAssembly: string | undefined;
  handleSelectTopLevelAssemblyClick: () => Promise<void>;
  iPJ: string;
}

const AssemblySection: React.FC<AssemblySectionProps> = ({
  topLevelAssembly,
  handleSelectTopLevelAssemblyClick,
  iPJ,
}): JSX.Element => (
  <SourceContentFormField required>
    <FormLabel>{text.sourceContentTopLevelAssemblyLabel}</FormLabel>
    <SourceInputContainer>
      <SourceContentTextInput
        fullWidth
        size="small"
        helperText={text.sourceContentTopLevelAssemblyComment}
        type="text"
        placeholder={text.NotSelectedLabel}
        inputProps={{ readOnly: true, 'data-testid': 'top-assembly-input' }}
        value={topLevelAssembly}
      />
      {topLevelAssembly ? (
        <SourceContentRightButton
          data-testid="top-assembly-replace-button"
          onClick={handleSelectTopLevelAssemblyClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="top-assembly-select-button"
          onClick={handleSelectTopLevelAssemblyClick}
          variant={iPJ ? Button.VARIANTS.PRIMARY : Button.VARIANTS.SECONDARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </SourceInputContainer>
  </SourceContentFormField>
);

export default AssemblySection;
