import { TemplateOutput } from 'mid-types';
import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  output?: TemplateOutput;
};

export class InvalidDraftOutputError extends CustomError {
  errorCode = ErrorCode.DeleteDraftError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
