import QuestionCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/QuestionCircleFilledIcon';
import Tooltip from '@adsk/alloy-react-tooltip';
import text from 'inventor.text.json';
import {
  MIDEmptyState,
  SummaryTable,
  SummaryTableRow,
  ThemedIconWrapper,
  TooltipWrapper,
  EMPTY_STATE_ILLUSTRATION_TYPES,
} from 'mid-react-common';
import React from 'react';
import {
  SourceContentPreviewImage,
  SourceContentPreviewImageWrapper,
  SourceContentPreviewWrapper,
  SourceContentSummaryTableWrapper,
  SourceContentTitle,
} from '../SourceContentTab.styles';

interface PreviewProps {
  topLevelFolder: string;
  iPJ: string;
  topLevelAssembly: string;
  path: string;
  thumbnailInBase64: string | undefined;
}

const Preview: React.FC<PreviewProps> = ({
  topLevelFolder,
  iPJ,
  topLevelAssembly,
  path,
  thumbnailInBase64,
}): JSX.Element => {
  const previewTableData: SummaryTableRow[] = [
    {
      title: text.sourceContentTopLevelFolderLabel,
      value: topLevelFolder,
    },
    {
      title: text.sourceContentIPJLabel,
      value: iPJ || text.NotSelectedLabel,
    },
    {
      title: text.sourceContentTopLevelAssemblyLabel,
      value: topLevelAssembly,
    },
    {
      title: text.sourceContentPath,
      value: path,
    },
  ];

  return (
    <SourceContentPreviewWrapper>
      <SourceContentSummaryTableWrapper>
        <SourceContentTitle>{text.sourceContentTitle}</SourceContentTitle>
        <SummaryTable data={previewTableData} />
      </SourceContentSummaryTableWrapper>
      <SourceContentPreviewImageWrapper>
        <SourceContentTitle>
          {text.sourceContentPreviewThumbnail}
          <TooltipWrapper>
            <Tooltip content={text.sourceContentPreviewTooltip}>
              <ThemedIconWrapper>
                <QuestionCircleFilledIcon fr={0} />
              </ThemedIconWrapper>
            </Tooltip>
          </TooltipWrapper>
        </SourceContentTitle>
        {thumbnailInBase64 ? (
          <SourceContentPreviewImage src={`data:image/jpeg;base64,${thumbnailInBase64}`} alt={text.previewImageAltText} />
        ) : (
          <MIDEmptyState
            title={text.notificationThumbnailFailed}
            illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.NO_RESULTS}
          />
        )}
      </SourceContentPreviewImageWrapper>
    </SourceContentPreviewWrapper>
  );
};

export default Preview;
