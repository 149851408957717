import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import text from 'inventor.text.json';
import { DraftTemplateIProperty } from 'mid-addin-lib';
import { FieldSetContainer, FieldsetRow, RowItem } from 'mid-react-common';
import { InputFormControl } from 'mid-react-common/global/styles/Common.styles';
import React from 'react';
import useInputLabel from '../../hooks/useInputLabel';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';

export interface IPropertyInfoProps {
  iProperty: DraftTemplateIProperty;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
}

export const IPropertyInfo: React.FC<IPropertyInfoProps> = ({ iProperty, callInputDataStoreUpdateHandler }): JSX.Element => {
  const { label, name, category, value } = iProperty;
  const { localInputLabel, handleLabelChange } = useInputLabel({
    label,
    callInputDataStoreUpdateHandler,
  });

  return (
    <>
      <FieldSetContainer>
        <FieldsetRow>
          <RowItem>
            <InputFormControl width="100%">
              <TextField size="small" label={text.editInputsPropertyName} value={name} disabled />
            </InputFormControl>
            <Tooltip title={text.editInputsLabelTooltip} placement="top">
              <InputFormControl width="100%">
                <TextField
                  size="small"
                  label={text.inputsLabel}
                  key={name}
                  value={localInputLabel}
                  onChange={handleLabelChange}
                />
              </InputFormControl>
            </Tooltip>
          </RowItem>
          <RowItem justifyContent="flex-end">
            <InputFormControl width="40%">
              <TextField size="small" label={text.editInputsPropertyType} value={category} disabled />
            </InputFormControl>
          </RowItem>
        </FieldsetRow>
        <FieldsetRow>
          <RowItem>
            <InputFormControl width="50%">
              <TextField fullWidth size="small" label={text.editInputsPropertyValue} value={value} disabled />
            </InputFormControl>
          </RowItem>
        </FieldsetRow>
      </FieldSetContainer>
    </>
  );
};
