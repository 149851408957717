import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { RightButton } from 'mid-react-common';

export const SourceContentHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const SourceContentTitle = styled('h2')`
  ${({ theme }) => theme.typography.heading4}
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
  line-height: ${({ theme }) => `${theme.var.marginBase * 2.5}px`};
`;

export const SourceContentRightButton = styled(RightButton)`
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
  margin-bottom: 4px; // To better alignment with input
  vertical-align: bottom;
`;

export const SourceContentFormWrapper = styled('div')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
  background-color: ${({ theme }) => theme.colors.primary.charcoal100};
`;

export const SourceContentForm = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const SourceContentTextInput = styled(TextField)`
  width: 300px;
  display: inline-block;
  vertical-align: top;
`;

export const SourceContentPreviewImage = styled('img')`
  width: 100%;
  height: auto;
`;

export const SourceContentPreviewWrapper = styled('div')`
  display: flex;
`;

export const SourceContentPreviewImageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 30%;
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const SourceContentSummaryTableWrapper = styled('div')`
  flex: 2 0 auto;
  max-width: 70%;

  table {
    word-break: break-word;
  }

  td:first-of-type {
    width: 20%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.desktop}) {
    td:first-of-type {
      width: 30%;
    }
  }
`;

export const SourceInputContainer = styled('div')`
  display: flex;
`;
