import { GridColDef } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import { midTheme } from 'mid-react-common';

// because it is inside Main component and it has margin base on each side
// In windows, Table has padding for each column
const DRAFT_TABLE_LEFT_RIGHT_MARGINS =
  midTheme.var.mainMargin * 2 + midTheme.var.marginBase * 2 + midTheme.var.paddingBase * 2;
const CHECKBOX_COLUMN_WIDTH = 52;

// for Columns width, react-table does not provide percentage values :(
// Math.round with innerWidth solution found in https://github.com/TanStack/table/issues/43
export const getColumns = (): GridColDef[] => [
  {
    field: 'templateName',
    headerName: text.draftTableColumnTemplateName,
    width: Math.round((window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.2),
  },
  {
    field: 'numberOfInputs',
    headerName: text.draftTableColumnNumberOfInputs,
    width: Math.round((window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.1),
  },
  {
    field: 'publishTo',
    headerName: text.draftTableColumnPublishTo,
    width: Math.round((window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.5),
  },
  {
    field: 'lastUpdated',
    headerName: text.draftTableColumnlastUpdated,
    width: Math.round((window.innerWidth - DRAFT_TABLE_LEFT_RIGHT_MARGINS - CHECKBOX_COLUMN_WIDTH) * 0.2),
  },
];
