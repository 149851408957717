import {
  DraftTemplate,
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  DraftTemplateOutput,
  InputRule,
  MetaInfo,
  MetaInfoPath,
  OutputType,
  SerializedBlocklyWorkspaceState,
} from 'mid-addin-lib';
import { DraftTemplateInput } from 'mid-addin-lib/interfaces/templates';

export enum CurrentDraftTemplateActionTypes {
  SET_DRAFT = 'SET_DRAFT',
  SET_NAME = 'SET_NAME',
  SET_SOURCE_MODEL = 'SET_SOURCE_MODEL',
  SET_PUBLISH_LOCATION = 'SET_PUBLISH_LOCATION',
  SET_RULE = 'SET_RULE',
  SET_CODE_BLOCKS_WORKSPACE = 'SET_CODE_BLOCKS_WORKSPACE',

  ADD_INPUTS = 'ADD_INPUTS',

  UPDATE_PARAMETER = 'UPDATE_PARAMETER',
  UPDATE_IPROPERTY = 'UPDATE_IPROPERTY',
  UPDATE_TABLE = 'UPDATE_TABLE',
  UPDATE_OUTPUT = 'UPDATE_OUTPUT',

  RESET_DRAFT = 'RESET_DRAFT',
  DELETE_TABLE = 'DELETE_TABLE',
}

type SetCurrentDraft = {
  type: CurrentDraftTemplateActionTypes.SET_DRAFT;
  payload: {
    draft: DraftTemplate;
  };
};

type ResetCurrentDraft = {
  type: CurrentDraftTemplateActionTypes.RESET_DRAFT;
};

type SetCurrentDraftName = {
  type: CurrentDraftTemplateActionTypes.SET_NAME;
  payload: {
    newDraftName: string;
  };
};

type SetCurrentDraftSourceModel = {
  type: CurrentDraftTemplateActionTypes.SET_SOURCE_MODEL;
  payload: {
    topLevelFolder: string;
    inventorProject: string;
    assembly: string;
    thumbnail: string;
  };
};

type SetCurrentDraftPublishLocation = {
  type: CurrentDraftTemplateActionTypes.SET_PUBLISH_LOCATION;
  payload: {
    account: MetaInfo;
    project: MetaInfo;
    folder: MetaInfoPath;
  };
};

type SetCurrentDraftRule = {
  type: CurrentDraftTemplateActionTypes.SET_RULE;
  payload: {
    rule: InputRule;
  };
};

type SetCurrentDraftCodeBlocksWorkspace = {
  type: CurrentDraftTemplateActionTypes.SET_CODE_BLOCKS_WORKSPACE;
  payload: {
    workspaceSerialized: SerializedBlocklyWorkspaceState | undefined;
  };
};

type AddCurrentDraftInputs = {
  type: CurrentDraftTemplateActionTypes.ADD_INPUTS;
  payload: {
    newInputs: DraftTemplateInput[];
  };
};

type UpdateCurrentDraftParameter = {
  type: CurrentDraftTemplateActionTypes.UPDATE_PARAMETER;
  payload: {
    parameterToUpdate: DraftTemplateInputParameter;
    updatedValue: { [key: string]: string | boolean | number };
  };
};

type UpdateCurrentDraftIProperty = {
  type: CurrentDraftTemplateActionTypes.UPDATE_IPROPERTY;
  payload: {
    iPropertyToUpdate: DraftTemplateIProperty;
    updatedValue: { [key: string]: string };
  };
};

type DeleteCurrentDraftTable = {
  type: CurrentDraftTemplateActionTypes.DELETE_TABLE;
};

type updateCurrentDraftOutput = {
  type: CurrentDraftTemplateActionTypes.UPDATE_OUTPUT;
  payload: {
    outputType: OutputType;
    updatedValue: DraftTemplateOutput;
  };
};

export type CurrentDraftTemplateActions =
  | SetCurrentDraft
  | ResetCurrentDraft
  | SetCurrentDraftName
  | SetCurrentDraftSourceModel
  | SetCurrentDraftPublishLocation
  | SetCurrentDraftRule
  | SetCurrentDraftCodeBlocksWorkspace
  | AddCurrentDraftInputs
  | AddCurrentDraftInputs
  | UpdateCurrentDraftParameter
  | UpdateCurrentDraftIProperty
  | updateCurrentDraftOutput
  | DeleteCurrentDraftTable;
