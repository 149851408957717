import text from 'inventor.text.json';
import { debounce } from 'lodash';
import { createFullPath, DraftTemplateOutput, getModelStates, OutputType } from 'mid-addin-lib';
import { DropdownItem } from 'mid-react-common';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import { revitFamilyCategories } from './constants';
import { RadioButtonControl } from './OutputsTab.styles';
import { OutputRepresentation } from './OutputsTab.types';

interface UseRevitFamilyOutputState {
  localRevitCategory: DropdownItem | null;
  localRevitFamily: string;
  localRepresentations: string[];
  revitFamilyCategoryList: DropdownItem[];
  availableRepresentations: OutputRepresentation[];
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRevitFamilyRepresentationChange: (value?: string) => void;
}

export const useRevitFamilyOutput = (): UseRevitFamilyOutputState => {
  const { currentDraft, updateCurrentDraftOutput } = useContext(DataContext);
  const [availableRepresentations, setAvailableRepresentations] = useState<OutputRepresentation[]>([]);

  // All dropdown items used by Category dropdown on Output tab - Revit Family Output
  const revitFamilyCategoryList: DropdownItem[] = revitFamilyCategories.map((category) => ({
    label: category,
    value: category,
  }));
  const currentDraftRevitFamilyOutput: DraftTemplateOutput | undefined = currentDraft.outputs.find(
    (output) => output.type === OutputType.RFA,
  );
  const revitFamilyCategory = currentDraftRevitFamilyOutput?.options?.category;
  // Get current revit family output representations (modelStates) in draft
  const revitRepresentations = currentDraftRevitFamilyOutput?.options?.modelStates ?? [];

  const [localRepresentations, setLocalRepresentations] = useState<string[]>(revitRepresentations);
  const [localRevitFamily, setLocalRevitFamily] = useState(
    currentDraftRevitFamilyOutput?.options?.family ?? currentDraft.name,
  );
  const [localRevitCategory, setLocalRevitCategory] = useState<DropdownItem | null>(
    revitFamilyCategory
      ? {
          label: revitFamilyCategory,
          value: revitFamilyCategory,
        }
      : null,
  );

  const debounceUpdateRevitFamilyOutput = debounce(
    (revitFamilyOutput: DraftTemplateOutput) => updateCurrentDraftOutput(OutputType.RFA, revitFamilyOutput),
    500,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRevitFamilyOutputDebounced = useCallback(
    debounceUpdateRevitFamilyOutput,
    [], // Creating it only once initially.
  );

  useEffect(() => {
    const updatedRevitFamilyOutput: DraftTemplateOutput = {
      type: OutputType.RFA,
      options: {
        category: localRevitCategory?.value.toString(),
        family: localRevitFamily,
        modelStates: localRepresentations,
      },
    };
    onRevitFamilyOutputDebounced(updatedRevitFamilyOutput);
  }, [localRepresentations, localRevitCategory?.value, localRevitFamily, onRevitFamilyOutputDebounced]);

  const fetchModelStates = useCallback(async (iamFileFullPath: string) => {
    const modelStates = await getModelStates(iamFileFullPath);
    const representationList: OutputRepresentation[] = modelStates.map((modelState) => ({
      name: modelState,
      status: text.outputAvailable,
      StatusControl: RadioButtonControl,
      statusProps: { value: modelState, disabled: false },
    }));
    setAvailableRepresentations(representationList);
  }, []);

  useEffect(() => {
    const topLevelFolder = currentDraft.topLevelFolder;
    const assembly = currentDraft.assembly;
    if (topLevelFolder && assembly) {
      fetchModelStates(createFullPath(topLevelFolder, assembly));
    }
  }, [currentDraft.assembly, currentDraft.topLevelFolder, fetchModelStates]);

  const handleOnSelectCategory = (changes: { selectedItem?: DropdownItem | null }) => {
    if (!changes.selectedItem) {
      logError('Error: User must select a revit category from the dropdown.');
    } else {
      setLocalRevitCategory(changes.selectedItem);
    }
  };

  const handleRevitFamilyChange = (event: React.ChangeEvent<HTMLInputElement>) => setLocalRevitFamily(event.target.value);

  const handleRevitFamilyRepresentationChange = (value?: string) => {
    if (!value) {
      logError('Error: User must select at least one representation for Revit Family output.');
    } else {
      setLocalRepresentations([value]);
    }
  };

  return {
    localRevitCategory,
    localRevitFamily,
    localRepresentations,
    revitFamilyCategoryList,
    availableRepresentations,
    handleOnSelectCategory,
    handleRevitFamilyChange,
    handleRevitFamilyRepresentationChange,
  };
};
