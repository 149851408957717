import TextField from '@mui/material/TextField';
import text from 'inventor.text.json';
import { FieldSetContainer, FieldsetRow, RowItem } from 'mid-react-common';
import { InputFormControl } from 'mid-react-common/global/styles/Common.styles';
import React from 'react';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';
import { useValueConfigurationHeader } from '../ValueConfigurationHeader/useValueConfigurationHeader';
import { ValueConfigurationHeader } from '../ValueConfigurationHeader/ValueConfigurationHeader';

interface TextInputSectionProps {
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
  defaultValue: string;
  visible: boolean;
  readOnly: boolean;
}

const TextInputSection: React.FC<TextInputSectionProps> = ({
  callInputDataStoreUpdateHandler,
  defaultValue,
  visible,
  readOnly,
}) => {
  const { localShowInputInForm, localReadOnly, handleShowInputInFormChange, handleReadOnlyChange } =
    useValueConfigurationHeader({ visible, readOnly, callInputDataStoreUpdateHandler });

  return (
    <FieldSetContainer>
      <FieldsetRow>
        <RowItem>
          <ValueConfigurationHeader
            showInputInForm={localShowInputInForm}
            readOnly={localReadOnly}
            handleShowInputInFormChange={handleShowInputInFormChange}
            handleReadOnlyChange={handleReadOnlyChange}
          />
        </RowItem>
      </FieldsetRow>
      <FieldsetRow>
        <RowItem>
          <InputFormControl width="30%">
            <TextField
              type="text"
              size="small"
              label={text.inputLabelDefaultValue}
              value={defaultValue}
              inputProps={{ readOnly: true }}
              disabled
            ></TextField>
          </InputFormControl>
        </RowItem>
      </FieldsetRow>
    </FieldSetContainer>
  );
};

export default TextInputSection;
