import { useSortable } from '@dnd-kit/sortable';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ListItemText from '@mui/material/ListItemText';
import { SidebarListItem } from 'mid-react-common';
import React from 'react';
import { ListItemIconWrapper } from './EditInputs.styles';

export interface InputProps {
  id: string;
  sortingId: string;
  isSelected: boolean;
  name: string;
  handleSelection: (event: React.MouseEvent<HTMLDivElement>) => void;
  renderIcon: () => JSX.Element;
}

export const Input: React.FC<InputProps> = ({ id, sortingId, isSelected, name, handleSelection, renderIcon }) => {
  const { listeners, attributes, isDragging } = useSortable({ id: sortingId });

  return (
    <SidebarListItem data-testid={id} id={sortingId} selected={isSelected} onClick={handleSelection}>
      {renderIcon()}
      <ListItemText primary={name} />
      <ListItemIconWrapper minwidth={0} isDragging={isDragging} {...attributes} {...listeners} data-testid="dragHandle">
        <DragHandleIcon />
      </ListItemIconWrapper>
    </SidebarListItem>
  );
};
