import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium};
  margin: auto;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`} 0;
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.tablet}) {
    width: 70%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.laptop}) {
    width: 50%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.desktop}) {
    width: 35%;
  }
  max-height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`});
  overflow: auto;
`;

export const TemplateSummary = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const CompleteDialog = styled('div')`
  text-align: center;
`;

export const Title = styled('h4')`
  ${({ theme }) => theme.typography.heading4}
  margin: 0;
  padding: 0 0 0 ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const PublishingErrorMessageWrapper = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  border: 1px solid ${({ theme }) => `${theme.colors.secondary.red600}`};
  color: ${({ theme }) => `${theme.colors.secondary.red600}`};
`;
