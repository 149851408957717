import Button from '@adsk/alloy-react-button';
import CalculatorIcon from '@adsk/alloy-react-icon/es/lib/CalculatorIcon';
import PlusCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/PlusCircleFilledIcon';
import { closestCorners, DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ListItemText } from '@mui/material';
import { DraftTemplateInput } from 'mid-addin-lib';
import { Label, SidebarButton, SidebarComponent, SidebarList, SidebarListItem } from 'mid-react-common';
import { isDraftTemplateIProperty } from 'mid-utils';
import React from 'react';
import text from '../../../../../inventor.text.json';
import { ItemCount, ListItemIconWrapper } from '../EditInputs.styles';
import { SortableInput } from '../SortableInput';
import { useEditInputsSidebar } from './useEditInputsSidebar';

export interface EditInputsSidebarProps {
  selectedInputs: DraftTemplateInput[];
  selectedInput: DraftTemplateInput | null;
  handleChangeSelectedInputs: () => void;
  handleInputSelection: (input: DraftTemplateInput) => void;
  isFormDisabled?: boolean;
}

export const EditInputsSidebar: React.FC<EditInputsSidebarProps> = ({
  selectedInput,
  selectedInputs,
  handleChangeSelectedInputs,
  handleInputSelection,
}): JSX.Element => {
  const { currentDraggingInput, handleDragStart, handleInputSelectionHandler, handleInputsDragEnd } = useEditInputsSidebar({
    selectedInputs,
    handleInputSelection,
  });
  const countSelectedItems = (): number => selectedInputs.length;

  const handlePreviewRules = () => undefined;

  const sensors = useSensors(useSensor(MouseSensor));

  return (
    <SidebarComponent>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handleChangeSelectedInputs}
        renderIcon={(p) => <PlusCircleFilledIcon {...p} />}
        variant={Button.VARIANTS.PRIMARY}
      >
        {text.buttonEditSelection}
      </SidebarButton>
      <SidebarButton size={Button.SIZES.SMALL} onClick={handlePreviewRules} renderIcon={(p) => <CalculatorIcon {...p} />}>
        {text.buttonPreviewRules}
      </SidebarButton>
      <Label>{text.inputsParameters}</Label>
      <SidebarList>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleInputsDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        >
          <SortableContext items={selectedInputs.map((input) => input.name)} strategy={verticalListSortingStrategy}>
            {selectedInputs.map((input: DraftTemplateInput) => {
              const isIProperty = isDraftTemplateIProperty(input);
              const isSelected =
                isIProperty && selectedInput && isDraftTemplateIProperty(selectedInput)
                  ? selectedInput?.id === input.id
                  : selectedInput?.name === input.name;
              const inputIdentifier = isIProperty ? input.id : input.name;

              return (
                <SortableInput
                  id={inputIdentifier}
                  sortingId={input.name}
                  isSelected={!!isSelected}
                  key={inputIdentifier}
                  name={input.name}
                  handleSelection={handleInputSelectionHandler(input)}
                  renderIcon={() =>
                    isIProperty ? (
                      <ListItemIconWrapper minwidth={30}>
                        <EditTwoToneIcon />
                      </ListItemIconWrapper>
                    ) : (
                      <></>
                    )
                  }
                />
              );
            })}
          </SortableContext>
          <DragOverlay>
            {
              <SidebarListItem>
                <ListItemText primary={currentDraggingInput} />
                <ListItemIconWrapper>
                  <DragHandleIcon />
                </ListItemIconWrapper>
              </SidebarListItem>
            }
          </DragOverlay>
        </DndContext>
      </SidebarList>
      <ItemCount>{countSelectedItems()} selected</ItemCount>
    </SidebarComponent>
  );
};
