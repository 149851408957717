import { isUndefined } from 'lodash';

enum ValidationFields {
  min = 'min',
  max = 'max',
  increment = 'increment',
}
interface ValidationResult {
  valid: boolean;
  validationField?: ValidationFields;
  message?: string;
  correctedValue?: number;
}

interface NumericConstraints {
  min?: number;
  increment?: number;
  max?: number;
  defaultValue?: number;
}

export const closestToIncrement = (defaultValue: number, min: number, increment: number): any => {
  // The increment number must be a factor of the difference between default and min values
  const numberToFactorize = defaultValue - min;
  // adding 1 because starts at index 0
  const valuesToCheck = [...Array(numberToFactorize + 1).keys()];
  // Check which factor is a valid multiple and return value closest to user's inputed increment
  const factors = valuesToCheck.filter((i) => numberToFactorize % i === 0);
  // return valid increment closest to what user inputed
  const closestToIncrement = factors.reduce((prev, curr) =>
    Math.abs(curr - increment) < Math.abs(prev - increment) ? curr : prev,
  );
  return closestToIncrement;
};

export const validateNumericValue = (value: number, constraints: NumericConstraints): ValidationResult => {
  const { min, max, increment, defaultValue } = constraints;
  const validateResult: ValidationResult = { valid: true };

  if (!isUndefined(max) && !isUndefined(defaultValue) && max < defaultValue) {
    // If the max inputted value is smaller than the default value
    // We auto set the max value to the default value
    validateResult.valid = false;
    validateResult.validationField = ValidationFields.max;
    validateResult.message = `Invalid entry: ${value}. Maximum value cannot be smaller than default value of ${defaultValue}.`;
    validateResult.correctedValue = defaultValue;
    return validateResult;
  }

  if (!isUndefined(min) && !isUndefined(defaultValue) && min > defaultValue) {
    // If the min inputted value is greater than the default value
    // We auto set the min value to the default value
    validateResult.valid = false;
    validateResult.validationField = ValidationFields.min;
    validateResult.message = `Invalid entry: ${value}. Minimum value cannot be greater than default value of ${defaultValue}.`;
    validateResult.correctedValue = defaultValue;
    return validateResult;
  }

  if (!isUndefined(increment) && !isUndefined(min) && !isUndefined(defaultValue) && (defaultValue - min) % increment !== 0) {
    // If the increment value is invalid
    // i.e., the default value cannot be reached from the min using the user's increment
    // We auto set the increment to the nearest valid number
    validateResult.valid = false;
    validateResult.validationField = ValidationFields.increment;
    validateResult.correctedValue = closestToIncrement(defaultValue, min, increment);
    validateResult.message = `Invalid entry: ${value}. Increment value is not a valid multiple and has been set to the smallest valid multiple.`;
    return validateResult;
  }
  return validateResult;
};
