import Button from '@adsk/alloy-react-button';
import PlusCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/PlusCircleFilledIcon';
import text from 'inventor.text.json';
import { LeftSection, RightButton, RightSection, Subtitle } from 'mid-react-common';
import React from 'react';

const ManageTemplates: React.FC<{
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}> = ({ handleNewTemplateClick, handleOpenSavedDraftsClick }): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.manageTemplates}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton size={Button.SIZES.SMALL} onClick={handleOpenSavedDraftsClick}>
        {text.buttonSavedDrafts}
      </RightButton>
      <RightButton
        renderIcon={(p) => <PlusCircleFilledIcon {...p} />}
        size={Button.SIZES.SMALL}
        onClick={handleNewTemplateClick}
      >
        {text.buttonNewProductDefinition}
      </RightButton>
    </RightSection>
  </>
);

export default ManageTemplates;
