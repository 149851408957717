import text from 'inventor.text.json';
import React, { useContext, useEffect, useState } from 'react';
import DraftConfigurationHeader from '../components/DraftConfigurationHeader/DraftConfigurationHeader';
import MIDTab from '../components/MIDTabs/MIDTab';
import MIDTabs from '../components/MIDTabs/MIDTabs';
import useMIDTabs from '../components/MIDTabs/useMIDTabs';
import InputsTab from '../components/Tabs/Inputs/InputsTab';
import OutputsTab from '../components/Tabs/Outputs/OutputsTab';
import RulesTab from '../components/Tabs/Rules/RulesTab';
import SourceContentTab from '../components/Tabs/SourceContent/SourceContentTab';
import NavigationContext from '../context/NavigationStore/Navigation.context';
import { Screens } from '../context/NavigationStore/navigationStore';
import TabProgressContext from '../context/TabProgressStore/TabProgress.context';
import { TABS } from '../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../types';

export const DraftConfigurationScreen: React.FC = (): JSX.Element => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { tabProgress } = useContext(TabProgressContext);
  const sourceContentTabProgress = tabProgress[TABS.SOURCE_CONTENT];
  const inputsTabProgress = tabProgress[TABS.INPUTS];
  const [disableInputsTab, setDisableInputsTab] = useState<boolean>(true);
  const [disableOutputsTab, setDisableOutputsTab] = useState<boolean>(true);
  const [disableRulesTab, setDisableRulesTab] = useState<boolean>(true);
  const { activeTab, resetToInitialActiveTab, handleTabClick } = useMIDTabs({
    initialActiveTab: TABS.SOURCE_CONTENT,
  });

  useEffect(() => {
    const sourceContentFormIsNotComplete = sourceContentTabProgress !== TabProgress.COMPLETED;
    setDisableInputsTab(sourceContentFormIsNotComplete);
    setDisableRulesTab(sourceContentFormIsNotComplete);
  }, [sourceContentTabProgress]);

  useEffect(() => {
    const inputsSelectionIsNotComplete = inputsTabProgress !== TabProgress.COMPLETED;
    setDisableOutputsTab(inputsSelectionIsNotComplete);
  }, [inputsTabProgress]);

  useEffect(() => {
    const sourceContentFormIsEmpty = sourceContentTabProgress === TabProgress.EMPTY;
    // This scenario is only possible if the user
    // decides to create a new template & discard
    // all unsaved changes. User is redirected to initial tab.
    if (sourceContentFormIsEmpty && activeTab !== TABS.SOURCE_CONTENT) {
      resetToInitialActiveTab();
    }
  }, [sourceContentTabProgress, activeTab, resetToInitialActiveTab]);

  const handlePublishClick = () => {
    setCurrentScreen(Screens.PUBLISHING);
  };

  return (
    <>
      <DraftConfigurationHeader handlePublishClick={handlePublishClick} />
      <MIDTabs activeTab={activeTab}>
        <MIDTab
          label={text.tabSourceContentLabel}
          id={TABS.SOURCE_CONTENT}
          handleTabClick={handleTabClick}
          tabProgress={sourceContentTabProgress}
        >
          <SourceContentTab />
        </MIDTab>
        <MIDTab
          label={text.tabParametersLabel}
          id={TABS.INPUTS}
          handleTabClick={handleTabClick}
          disabled={disableInputsTab}
          tabProgress={tabProgress[TABS.INPUTS]}
        >
          <InputsTab />
        </MIDTab>
        <MIDTab
          label={text.tabRulesLabel}
          id={TABS.RULES}
          handleTabClick={handleTabClick}
          disabled={disableRulesTab}
          tabProgress={tabProgress[TABS.RULES]}
        >
          <RulesTab />
        </MIDTab>
        <MIDTab
          label={text.tabOutputsLabel}
          id={TABS.OUTPUTS}
          handleTabClick={handleTabClick}
          disabled={disableOutputsTab}
          tabProgress={tabProgress[TABS.OUTPUTS]}
        >
          <OutputsTab />
        </MIDTab>
      </MIDTabs>
    </>
  );
};
