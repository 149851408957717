import { highlightBlockRegex } from 'mid-react-common';
import { TemplateInputType } from 'mid-types';
import { AvailableInputFunctionValues, BlocklyInputParameterDropdownValues, BlocklyState } from './BlocklyModule.types';
import { blockTypeByFunctionType, blockTypeByInputType, universalInputBlock, valueKey } from './constants';

type blockValue = string | number | boolean | string[] | number[] | undefined;

const generateUniversalInputsBlock = (
  input: BlocklyInputParameterDropdownValues,
  functionValue: AvailableInputFunctionValues,
  value: blockValue,
): BlocklyState => ({
  type: universalInputBlock,
  extraState: {
    inputsDropdown: JSON.stringify(input),
    functionsDropdown: functionValue,
  },
  inputs: {
    connecting_block: {
      block: generateConnectingBlock(input.type, functionValue, value),
    },
  },
  next: {},
});

const generateConnectingBlock = (
  inputType: TemplateInputType,
  functionValue: AvailableInputFunctionValues,
  value: blockValue,
): BlocklyState => {
  // if we are setting the 'value' key
  // we check by input type instead of function type
  const blockType = functionValue === valueKey ? blockTypeByInputType[inputType] : blockTypeByFunctionType[functionValue];

  switch (blockType) {
    case 'Boolean':
      return {
        type: 'logic_boolean',
        fields: {
          BOOL: value ? 'TRUE' : 'FALSE',
        },
      };
    case 'Number':
      return {
        type: 'math_number',
        fields: {
          NUM: value,
        },
      };
    case 'String':
      return {
        type: 'text',
        fields: {
          TEXT: value,
        },
      };
    case 'Array': {
      if (Array.isArray(value)) {
        const blockList: BlocklyState = {
          type: 'lists_create_with',
          inputs: {},
          extraState: {
            itemCount: value.length,
          },
        };
        if (inputType === TemplateInputType.MultiValueNumeric) {
          value?.forEach((optionValue, index) => {
            blockList.inputs[`ADD${index}`] = {
              block: {
                type: 'math_number',
                fields: {
                  NUM: optionValue,
                },
              },
            };
          });
          return blockList;
        }
        if (inputType === TemplateInputType.MultiValueText) {
          value?.forEach((optionValue, index) => {
            blockList.inputs[`ADD${index}`] = {
              block: {
                type: 'text',
                fields: {
                  TEXT: optionValue,
                },
              },
            };
          });
          return blockList;
        }
      }
      return {};
    }
    default: {
      return {};
    }
  }
};

const extractBlockIdfromCode = (jsCode: string): string => {
  const regex = new RegExp(`^${highlightBlockRegex}$`);
  const capturedGroups = jsCode.trim().match(regex);
  // the captured groups usually will contains at least two items
  // the first one is the fully matched one
  // the second one is the captured one
  if (capturedGroups && capturedGroups.length >= 2) {
    return capturedGroups[1];
  }
  return '';
};
export { generateUniversalInputsBlock, extractBlockIdfromCode };
