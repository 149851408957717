import text from 'inventor.text.json';
import { openMidWebapp, PublishStatus } from 'mid-addin-lib';
import {
  HeaderWrapper,
  initialModalState,
  InventorAddInMoniker,
  ModalContext,
  TopHeader,
  VersionInfo,
} from 'mid-react-common';
import React, { useContext, useEffect, useState } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import { generateAddInVersionForDisplay, logError } from 'mid-utils';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../types';
import Initial from './SubHeaders/Initial';
import ManageTemplates from './SubHeaders/ManageTemplates';
import PublishingHeader from './SubHeaders/PublishingHeader';
import { PublishLocationScreenHeader } from './SubHeaders/PublishLocationScreenHeader';
import SelectDataset from './SubHeaders/SelectDataset';
import SourceContent from './SubHeaders/SourceContent';

const unsavedChangesWarningMessage = (
  <>
    <div>{text.unsavedChangesLossWarning}</div>
    <div>{text.unsavedChangesContinueQuestion}</div>
  </>
);

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { draftHasUnsavedChanges, resetCurrentDraft, currentDraftPublishStatus } = useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const { setModalState } = useContext(ModalContext);
  const [addinVersionTag, setAddinVersionTag] = useState<string | undefined>(undefined);

  useEffect(() => {
    generateAddInVersionForDisplay(InventorAddInMoniker)
      .then((addinVersion) => {
        setAddinVersionTag(addinVersion);
      })
      .catch((error) => {
        logError(error);
      });
  }, []);

  const sourceContentProgress: TabProgress = tabProgress[TABS.SOURCE_CONTENT];

  // Should be used inside a `WithConfirmation` handler
  const _handleNewTemplateClick = () => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
    resetCurrentDraft();
  };

  // Should be used inside a `WithConfirmation` handler
  const _handleOpenSavedDraftsClick = () => {
    setCurrentScreen(Screens.DRAFT_TEMPLATES);
    resetCurrentDraft();
  };

  const handleNewTemplateClickWithConfirmation = (): void => {
    if (draftHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        title: text.unsavedChanges,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: _handleNewTemplateClick,
      });
    } else {
      _handleNewTemplateClick();
    }
  };

  const handleManageTemplatesClick = (): void => {
    setCurrentScreen(Screens.MANAGE_TEMPLATES);
  };

  const handleOpenSavedDraftsClickWithConfirmation = (): void => {
    if (draftHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        title: text.unsavedChanges,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: _handleOpenSavedDraftsClick,
      });
    } else {
      _handleOpenSavedDraftsClick();
    }
  };

  const handleEditTemplateClick = () => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
  };

  return (
    <HeaderWrapper>
      <TopHeader>
        {currentScreen === Screens.DRAFT_TEMPLATES && (
          <Initial
            handleNewTemplateClick={handleNewTemplateClickWithConfirmation}
            handleMidWebPortalClick={openMidWebapp}
            handleManageTemplatesClick={handleManageTemplatesClick}
          />
        )}
        {currentScreen === Screens.DRAFT_CONFIGURATION && sourceContentProgress === TabProgress.EMPTY && (
          <SelectDataset handleOpenSavedDraftsClick={handleOpenSavedDraftsClickWithConfirmation} />
        )}
        {currentScreen === Screens.DRAFT_CONFIGURATION && sourceContentProgress === TabProgress.COMPLETED && (
          <SourceContent
            handleNewTemplateClick={handleNewTemplateClickWithConfirmation}
            handleOpenSavedDraftsClick={handleOpenSavedDraftsClickWithConfirmation}
          />
        )}
        {currentScreen === Screens.MANAGE_TEMPLATES && (
          <ManageTemplates
            handleOpenSavedDraftsClick={handleOpenSavedDraftsClickWithConfirmation}
            handleNewTemplateClick={handleNewTemplateClickWithConfirmation}
          />
        )}
        {currentScreen === Screens.PUBLISHING && currentDraftPublishStatus === PublishStatus.IDLE && (
          <PublishLocationScreenHeader handleEditTemplateClick={handleEditTemplateClick} />
        )}
        {currentScreen === Screens.PUBLISHING && currentDraftPublishStatus === PublishStatus.LOADING && (
          <PublishingHeader subtitle={text.subHeaderPublishingInProgress} />
        )}
        {currentScreen === Screens.PUBLISHING && currentDraftPublishStatus === PublishStatus.COMPLETE && (
          <PublishingHeader subtitle={text.subHeaderPublishingComplete} />
        )}
        {currentScreen === Screens.PUBLISHING && currentDraftPublishStatus === PublishStatus.FAILURE && (
          <PublishingHeader subtitle={text.subHeaderPublishingFailed} />
        )}
        <VersionInfo webVersion={process.env.REACT_APP_IVTW_VERSION} addinVersion={addinVersionTag} />
      </TopHeader>
    </HeaderWrapper>
  );
};

export default Header;
