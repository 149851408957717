import Button, { ButtonGroup } from '@adsk/alloy-react-button';
import PlusCircleFilledIcon from '@adsk/alloy-react-icon/es/lib/PlusCircleFilledIcon';
import text from 'inventor.text.json';
import React from 'react';
import InputsFilter from './InputsFilter';
import { InputsFilterDropDown, InputsHeaderWrapper } from './InputsSelection.styles';

export interface InputsHeaderProps {
  buttonGroupValue: string;
  handleOnParameterOriPropertyButtonChange: (value: string) => void;
  currentFilter: string;
  setCurrentFilter: React.Dispatch<React.SetStateAction<string>>;
  filterItems: string[];
  totalSelected: number;
  handleAddInputsButtonClick: () => void;
}

const InputsHeader: React.FC<InputsHeaderProps> = ({
  buttonGroupValue,
  handleOnParameterOriPropertyButtonChange,
  currentFilter,
  setCurrentFilter,
  filterItems,
  totalSelected,
  handleAddInputsButtonClick,
}): JSX.Element => (
  <InputsHeaderWrapper>
    <ButtonGroup<string>
      onChange={handleOnParameterOriPropertyButtonChange}
      value={buttonGroupValue}
      data-testid="test-group-button-id"
    >
      <Button value={text.inputsParameters} data-testid="test-parameters-button-id">
        {text.inputsParameters}
      </Button>
      <Button value={text.inputsProperties} data-testid="test-iproperties-button-id">
        {text.inputsProperties}
      </Button>
    </ButtonGroup>
    <InputsFilterDropDown>
      <InputsFilter items={filterItems} initialSelectedItem={currentFilter} setFilter={setCurrentFilter} />
    </InputsFilterDropDown>
    <Button
      data-testid="test-add-inputs-button-id"
      renderIcon={(p) => <PlusCircleFilledIcon {...p} />}
      variant={Button.VARIANTS.PRIMARY}
      disabled={!totalSelected}
      onClick={handleAddInputsButtonClick}
    >
      {totalSelected ? `${text.addInputsButtonTextAdd} ${totalSelected}` : `${text.addInputsButtonTextAdd}`}
    </Button>
  </InputsHeaderWrapper>
);

export default InputsHeader;
