import React from 'react';
import { Subtitle, MiddleSection } from 'mid-react-common';

const PublishingHeader: React.FC<{ subtitle: string }> = ({ subtitle }): JSX.Element => (
  <MiddleSection>
    <Subtitle>{subtitle}</Subtitle>
  </MiddleSection>
);

export default PublishingHeader;
