import React from 'react';
import DraftTable from '../components/DraftTable/DraftTable';
import useDraftTemplateScreen from './useDraftTemplateScreen';

export const DraftTemplatesScreen: React.FC = (): JSX.Element => {
  const { drafts, loading, handleEditTemplateClick, handleDeleteTemplatesClick } = useDraftTemplateScreen();

  return (
    <DraftTable
      drafts={drafts}
      isFetching={loading}
      handleEditTemplateClick={handleEditTemplateClick}
      handleDeleteTemplatesClick={handleDeleteTemplatesClick}
    />
  );
};
