import { ErrorCode } from '../errorCode';
import { CustomError } from '../customError';

export class EmptyTopLevelError extends CustomError {
  errorCode = ErrorCode.EmptyTopLevelError;

  constructor(message: string) {
    super(message);
  }
}
