import Button from '@adsk/alloy-react-button';
import { Column } from '@adsk/alloy-react-table';
import text from 'inventor.text.json';
import { isUndefined } from 'lodash';
import { DraftTemplateInputParameter } from 'mid-addin-lib';
import { ContentTitle, ContentWrapper, FlexContainer } from 'mid-react-common';
import { TemplateInputType } from 'mid-types';
import { isDraftTemplateIProperty, isNotDraftTemplateIProperty } from 'mid-utils';
import React from 'react';
import BooleanInputSection from '../../../BooleanInputSection/BooleanInputSection';
import { IPropertyInfo } from '../../../iPropertyInfoSection/iPropertyInfo';
import { MultiValueListSection } from '../../../MultiValueListSection/MultiValueListSection';
import NumericInputSection from '../../../NumericInputSection/NumericInputSection';
import { ParameterInfo } from '../../../ParameterInfoSection/ParameterInfo';
import TextInputSection from '../../../TextInputSection/TextInputSection';
import { HandleSelectedInputUpdateType } from '../useInputsTab';
import DependencyRules from './DependencyRules/DependencyRules';
import useDependencyRulesDropdown from './DependencyRules/useDependencyRulesDropdown';
import { CodeblocksButtonContainer, EditInputsContainer, EnableDisableFormWrapper } from './EditInputs.styles';
import { RuleTableModel } from './EditInputs.types';
import { EditInputsSidebar } from './EditInputsSidebar/EditInputsSidebar';
import useEditInputs from './useEditInputs';

interface EditInputsProp {
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

const EditInputs: React.FC<EditInputsProp> = ({ setIsEditStep, handleSelectedInputDataStoreUpdate }): JSX.Element => {
  const {
    selectedInput,
    selectedInputs,
    handleChangeSelectedInputs,
    handleInputSelection,
    callInputDataStoreUpdateHandler,
    isCodeblocksWorkspaceUndefined,
    setCodeblocksWorkspaceToUndefined,
  } = useEditInputs({
    setIsEditStep,
    handleSelectedInputDataStoreUpdate,
  });

  const { currentControllingInputDropdownValue, controllingInputDropdownItems, handleSelectControllingInput } =
    useDependencyRulesDropdown({
      selectedParameterName: selectedInput && isNotDraftTemplateIProperty(selectedInput) ? selectedInput.name : undefined,
      handleSelectedInputDataStoreUpdate,
    });
  const dependencyRuleTableData: RuleTableModel[] = [
    {
      rule: 1,
      condition: text.dependencyRuleTableTrueCondition,
      response: text.dependencyRuleTableTrueResponse,
    },
    {
      rule: 2,
      condition: text.dependencyRuleTableFalseCondition,
      response: text.dependencyRuleTableFalseResponse,
    },
  ];

  const dependencyRuleTableColumns: Column<RuleTableModel>[] = [
    {
      id: 'rule',
      accessor: 'rule',
      renderHeader: () => text.dependencyRuleTableRule,
    },
    {
      id: 'condition',
      accessor: 'condition',
      renderHeader: () => `${currentControllingInputDropdownValue} ${text.dependencyRuleTableCondition}`,
    },
    {
      id: 'response',
      accessor: 'response',
      renderHeader: () =>
        `${(selectedInput && isNotDraftTemplateIProperty(selectedInput) && selectedInput.name) || ''} ${
          text.dependencyRuleTableResponse
        }`,
    },
  ];

  const renderParameterComponents = (param: DraftTemplateInputParameter) => {
    switch (param.type) {
      case TemplateInputType.MultiValueNumeric:
      case TemplateInputType.MultiValueText:
        return (
          <MultiValueListSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            items={param.values}
            value={param.value}
            visible={param.visible}
            readOnly={param.readOnly}
            disableInput
          />
        );
      case TemplateInputType.Boolean:
        return (
          <BooleanInputSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            trueLabel={param.trueLabel || ''}
            falseLabel={param.falseLabel || ''}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      case TemplateInputType.Text:
        return (
          <TextInputSection
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            defaultValue={param.value}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      case TemplateInputType.Numeric:
        return (
          // TODO: Replace alloy TextInput from NumericInputSection due to this interface problem?
          <NumericInputSection
            defaultValue={param.value.toString()}
            // When param changes, we need a new instance of this component to be mounted
            // to avoid previous params values persisting
            // When react sees a new key, it will mount a new instance of the component
            key={`${param.name}_${param.value}`}
            callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
            // In React, having the value prop with a value of undefined on a form element indicates
            // it being uncontrolled, meaning it does not rely on a prop for its value.
            // React would give you an error in the console if you change the value of the value
            // prop on a form element to undefined (switching from controlled to uncontrolled).
            // In our case, if undefined, the value of the previous component persists,
            // React thinks as it hasn't been updated, causing a bug. So, value || empty string
            // https://github.com/JedWatson/react-select/issues/3066#issuecomment-643000641
            min={isUndefined(param.min) ? '' : param.min}
            max={isUndefined(param.max) ? '' : param.max}
            increment={isUndefined(param.increment) ? '' : param.increment}
            visible={param.visible}
            readOnly={param.readOnly}
          />
        );
      default:
        <></>;
    }
  };

  return (
    <>
      {!isCodeblocksWorkspaceUndefined && (
        <CodeblocksButtonContainer>
          <Button variant={Button.VARIANTS.PRIMARY} onClick={setCodeblocksWorkspaceToUndefined}>
            {text.discardRules}
          </Button>
        </CodeblocksButtonContainer>
      )}
      <EnableDisableFormWrapper disabled={!isCodeblocksWorkspaceUndefined}>
        <FlexContainer>
          <EditInputsSidebar
            selectedInputs={selectedInputs}
            selectedInput={selectedInput}
            handleChangeSelectedInputs={handleChangeSelectedInputs}
            handleInputSelection={handleInputSelection}
            isFormDisabled={!isCodeblocksWorkspaceUndefined}
          />
          <ContentWrapper>
            {(selectedInput && isNotDraftTemplateIProperty(selectedInput) && (
              <>
                <ContentTitle>
                  {text.editInputsTitle} <strong>{selectedInput.name}</strong>
                </ContentTitle>
                <EditInputsContainer>
                  <ParameterInfo
                    parameter={selectedInput}
                    callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
                  />
                  {renderParameterComponents(selectedInput)}
                  <DependencyRules
                    items={controllingInputDropdownItems}
                    value={currentControllingInputDropdownValue}
                    dependencyRuleTableColumns={dependencyRuleTableColumns}
                    dependencyRuleTableData={dependencyRuleTableData}
                    handleSelectControllingInput={handleSelectControllingInput}
                  />
                </EditInputsContainer>
              </>
            )) ||
              (selectedInput && isDraftTemplateIProperty(selectedInput) && (
                <>
                  <ContentTitle>
                    {text.editInputsTitle} <strong>{selectedInput.name}</strong>
                  </ContentTitle>
                  <EditInputsContainer>
                    <IPropertyInfo
                      iProperty={selectedInput}
                      callInputDataStoreUpdateHandler={callInputDataStoreUpdateHandler}
                    />
                  </EditInputsContainer>
                </>
              ))}
          </ContentWrapper>
        </FlexContainer>
      </EnableDisableFormWrapper>
    </>
  );
};

export default EditInputs;
